const baseURLFunc = () => {
  // const url = "http://abacicount.com";

  const url = window.location.origin.split(":3000")[0];
  // console.log(url)
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return url + ":8000";
  } else {
    return url;
  }
};

export const baseURL = baseURLFunc();
// export const baseURL = 'https://envirol.abacitechs.com'
// export const baseURL = window.location.origin
