import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";

import axios from "axios";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import GraphLoader from "../../../../components/GraphLoader/GraphLoader";
import { useParams } from "react-router-dom";
import { addNewCounter, editCounter } from "../../../../store/counterSlice";

function EditCounterLine(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  // const [loader,setLoader] = useState(true)
  const dispatch = useDispatch();
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const [loader, setLoader] = useState({
    loader1: true,
    loader2: true,
    loader3: true,
    loader4: true,
  });
  const { id } = useParams();
  const [sensor, setSensor] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [counter, setCounter] = useState([]);
  const [counterList, setCounterList] = useState([]);

  const [direction] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [selectedCounter, setSelectedCounter] = useState(null);
  const [selectedDirection1, setSelectedDirection1] = useState({
    label: "Yes",
    value: "Yes",
  });

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("zone", id);
    formData.append("sensor", props.item.sensor.id);
    formData.append("counter_line", selectedCounter.value);
    formData.append(
      "follow_sensor_direction",
      selectedDirection1?.value === "Yes" ? 1 : 0
    );
    setwaitingForAxios(true);
    const url = `/abaci_count/zones/counterassignedtozone/${props.item.id}`;
    axios
      .patch(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        dispatch(editCounter(response.data));
        // props.tableRef.current.onQueryChange();
        Toastr("success", "Reassigned Counter line Succesfully");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const handleSensor = (values) => {
    setSelectedCounter(null);
    // console.log(values);
    setSelectedSensor(values);

    // console.log(values.manufacturer.manufacturer_name);
    if (values && values.manufacturer.manufacturer_name !== "Axis") {
      const temp = counterList.filter(
        (data) => data.counter_line.line_name === "Line 1"
      );

      setCounter(temp);
    } else {
      setCounter(counterList);
    }
  };

  useEffect(() => {
    if (props.item) {
      console.log(props.item);
      setSelectedDirection1({
        label: props.item.follow_sensor_direction ? "Yes" : "No",
        value: props.item.follow_sensor_direction,
      });

      const LineUrl = `/abaci_count/sensor_counter_line_no_pagination?sensor=${props.item.sensor.id}`;
      axios
        .get(LineUrl)
        .then((response) => {
          const temp = response.data.map((data) => ({
            ...data,
            label: data.line_name,
            value: data.id,
          }));
          setCounter(temp);
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }, [props]);

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          {/* <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="Sensor" sm={3}>
                Sensor
              </Label>
              <Col sm={9}>
                <CreatableSelect
                  placeholder="Sensor"
                  isClearable
                  isValidNewOption={() => false}
                  promptTextCreator={() => false}
                  onChange={(values) => handleSensor(values)}
                  options={sensor}
                  isMulti={false}
                  value={selectedSensor}
                />
              </Col>
            </FormGroup> */}
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Counter" sm={3}>
              Counter
            </Label>
            <Col sm={9}>
              <CreatableSelect
                placeholder="Counter"
                isClearable
                isValidNewOption={() => false}
                promptTextCreator={() => false}
                onChange={(values) => setSelectedCounter(values)}
                options={counter}
                isMulti={false}
                value={selectedCounter}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Follow Sensor Direction" sm={3}>
              Follow Sensor Direction
            </Label>
            <Col sm={9}>
              <CreatableSelect
                placeholder=""
                isClearable
                isValidNewOption={() => false}
                promptTextCreator={() => false}
                onChange={(values) => setSelectedDirection1(values)}
                options={direction}
                isMulti={false}
                value={selectedDirection1}
              />
            </Col>
          </FormGroup>
          {/* <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Counter " sm={3}>
              Counter
            </Label>
            <Col sm={9}>
              <input
                placeholder="Counter "
                type="text"
                className="form-control"
                {...register("counter", {
                  required: true,
                })}
              />
              {errors.counter?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Counter direction" sm={3}>
              Counter Direction
            </Label>
            <Col sm={9}>
              <input
                placeholder="Counter Direction"
                type="text"
                className="form-control"
                {...register("counter_direction", {
                  required: true,
                })}
              />
              {errors.counter_direction?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Assigned Direction" sm={3}>
              Assigned Direction
            </Label>
            <Col sm={9}>
              <input
                placeholder="Assigned Direction"
                type="text"
                className="form-control"
                {...register("assigned_direction", {
                  required: true,
                })}
              />
              {errors.assigned_direction?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup> */}

          <ModalFooter>
            <Button
              type="button"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
            <Button
              type="submit"
              style={{ backgroundColor: "#5664D2", borderColor: "#5664D2" }}
              className="w-xs waves-effect waves-light me-1"
              // onClick={() => {
              //     onSubmit();
              // }}
              disabled={waitingForAxios || !selectedDirection1}
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                " Submit"
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default EditCounterLine;
