import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { APP_NAME, truncateString } from "../../../helpers/Constants";
import MaterialTable from "material-table";
import { tableIcons } from "../../../helpers/MaterialIcons";
import axios from "axios";
import Moments from "../../../helpers/Moment";
import NotificationModal from "./NofificationModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/no_search_results.json";
import notfound from "../../../assets/lotties/not_found_lottie.json";
import TimerIcon from "@mui/icons-material/AccessAlarm";
export default function NotificationsPage() {
  const [data, setData] = useState([]);
  const [downloadIndicator, setDownloadIndicator] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [modalShow, setModalShow] = useState(false);
  const [rowData, setRowData] = useState({});
  const tableRef = useRef();
  const [loader, setloading] = useState(true);
  const totalRecordsCount = useRef(0);
  const urlBackup = useRef();
  const [searchActive, setSearchActive] = useState(false);

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/" },
      { title: "Notifications", link: "#" },
    ],
  });
  const selected_site = useSelector((state) => state.siteSlice.selected_site);

  useEffect(() => {
    const abortController = new AbortController();

    setDashboardState({
      breadcrumbItems: [
        { title: APP_NAME, link: "/" },
        { title: "Notifications", link: "#" },
      ],
    });

    return () => {
      abortController.abort();
      setData([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Notification",
      field: "alert_message2",
      render: (rowData) =>
        !rowData.is_read ? (
          <>
            <i
              className="ri-checkbox-blank-circle-fill"
              style={{
                fontSize: "8px",
                color: "#4F72B7",
                marginRight: "5px",
              }}
            ></i>
            {rowData.alert_message2}
          </>
        ) : (
          <> {rowData.alert_message2}</>
        ),
    },
    { title: "Type", field: "alert_type" },
    { title: "Time", field: "time" },
    // {
    //   title: "",
    //   field: "",
    //   render: (rowData) =>
    //     rowData.is_read ? <DoneAllIcon /> : <DoneAllIcon />,
    // },
  ];

  const ModalToggle = (data) => {
    setModalShow(!modalShow);
    setRowData(data);
  };

  useEffect(() => {
    if (selected_site !== null) {
      tableRef.current.onQueryChange();
    }
  }, [selected_site]);

  return (
    <React.Fragment>
      {modalShow && (
        <NotificationModal
          title={
            <>
              {rowData.alert_type === "Connection Alert" ? (
                <CameraAltIcon
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              ) : (
                <TimerIcon
                  style={{
                    fontSize: "22px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              )}
              {rowData.alert_type}
            </>
          }
          toggle={ModalToggle}
          isOpen={modalShow}
          item={rowData}
          tableRef={tableRef}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Notifications"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    style={{ zIndex: "0" }}
                    title=" "
                    columns={columns}
                    onChangeRowsPerPage={setPageSize}
                    // isLoading={loader}
                    tableRef={tableRef}
                    data={(query) => {
                      // console.log(query)
                      return new Promise((resolve, reject) => {
                        let orderBy = "";
                        // let search =''
                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }
                        if (query.search) {
                          setloading(true);
                          setSearchActive(true);
                        } else {
                          setSearchActive(false);
                        }
                        let url = `/abaci_count/alerts?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;
                        if (selected_site !== null) {
                          url += `&site=${selected_site?.id}`;
                        }
                        // if (Object.keys(date).length !== 0){
                        //   url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                        // }

                        urlBackup.current = url;
                        axios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;

                            // tableRef.current.dataManager.changePageSize(query.pageSize)
                            // console.log(response.data.results)
                            const LoginDetails = response.data?.results;
                            // dispatch(addGtcc(response.data.results))
                            const TempData = LoginDetails.map((data) => ({
                              ...data,
                              alert_type: data.alert_type
                                ? data.alert_type
                                : "-",

                              time: data?.time
                                ? Moments(data.time, "datetime")
                                : "-",
                              alert_message: data.alert_message,
                              alert_message2: truncateString(
                                data.alert_message
                              ),
                            }));
                            resolve({
                              data: TempData,
                              page:
                                response.data?.results.length === 0
                                  ? 0
                                  : query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          })
                          .finally(() => {
                            setloading(false); // Indicate that the query is complete
                          });
                      });
                    }}
                    isLoading={downloadIndicator}
                    onRowClick={(event, rowData) => ModalToggle(rowData)}
                    options={{
                      pageSize: pageSize,
                      exportFileName: "Login Details",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: false,
                      debounceInterval: 500,
                      // exportCsv: async () => exportData("csv_download"),
                      // exportPdf: async () => exportData("pdf_download"),
                      // filtering: filterEnabled
                    }}
                    icons={tableIcons}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <div style={{ height: "300px" }}>
                            {!loader && (
                              <>
                                <Player
                                  autoplay
                                  loop
                                  src={searchActive ? lottie : notfound}
                                  style={{
                                    height: "200px",
                                    width: "170px",
                                  }}
                                />
                                <span style={{ fontSize: "13px" }}>
                                  {searchActive
                                    ? "Oops! No results found !"
                                    : " Oops! No notifications to dispaly"}
                                </span>
                              </>
                            )}
                          </div>
                        ),
                      },
                    }}
                  />
                </div>

                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
}
