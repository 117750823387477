// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  camera: null,
};

const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    addCamera(state, action) {
      state.camera = action.payload;
    },
    addNewCamera(state, action) {
      state.camera = [...state.camera, action.payload];
    },
    editCamera(state, action) {
      state.camera = state.camera.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    deleteCamera(state, action) {
      state.camera = state.camera.filter((data) => data.id !== action.payload);
    },
  },
});

export const { addCamera, addNewCamera, editCamera, deleteCamera } =
  cameraSlice.actions;
export default cameraSlice.reducer;
