import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { APP_NAME, payload, post_url } from "../../../helpers/Constants";
import LineGraph from "./LineGraph";
import axios from "axios";
import "bootstrap-daterangepicker/daterangepicker.css";
import MiniWidgets from "./MiniWidgets";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import HeatMapChart from "./HeatMapChart";
import BarGraph from "./BarGraph";
// import Moments from "../../../helpers/Moment";
import moment from "moment";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
export default function Index() {
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const [loader, setLoader] = useState(true);

  const [dateAndTime, setDateAndTime] = useState({});
  const [peopleCount, setPeopleCount] = useState([
    {
      id: 1,
      icon: "ri-user-shared-fill",
      title: "People In",
      value: 0,
      desc: "Total People In",
    },
    {
      id: 2,
      icon: "ri-user-unfollow-fill",
      title: "People Exit",
      value: 0,
      desc: "Total People Exit",
    },
    {
      id: 3,
      icon: "ri-team-fill",
      title: "Occupancy",
      value: 0,
      desc: " Total Occupancy",
    },
    {
      id: 4,
      icon: "ri-calendar-event-fill",
      title: "Busiest Day",
      desc: " ",
      day: "--",
      day_letters: "---",
      desc: "Current Week",
    },
    {
      id: 5,
      icon: "ri-time-fill",
      title: "Busiest Hour",
      desc: " ",
      hour: "-- --",
      desc: "Current Week",
    },
  ]);

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Dashboard", link: "#" },
    ],
  });

  useEffect(() => {
    setLoader(true);
    if (selected_site !== null) {
      const data = {
        url_params: { site: selected_site?.id },
        ...payload,
        url: "/abaci_count/site_busiest_day_hour",
      };

      axios
        .post(post_url, data)
        .then((res) => {
          // console.log(res.data);
          setDateAndTime(res.data);
          setPeopleCount([
            {
              icon: "ri-user-shared-fill",
              title: " People In",
              value: res.data?.site_count_in ? res.data.site_count_in : 0,
              desc: "Total People In",
            },
            {
              icon: "ri-user-unfollow-fill",
              title: "People Exit",
              value: res.data?.site_count_out ? res.data.site_count_out : 0,
              desc: "Total People Exit",
            },
            {
              icon: "ri-team-fill",
              title: "Occupancy",
              value: res.data?.site_count_occ,
              value: res.data?.site_count_occ ? res.data.site_count_occ : 0,

              desc: " Total Occupancy",
            },
            {
              icon: "ri-calendar-event-fill",
              title: "Busiest Day",
              value: res.data?.busiest_day?.count,

              day: res.data?.busiest_day?.date
                ? moment(res.data?.busiest_day?.date).format("DD-MM-YYYY")
                : "--",
              day_letters: res.data?.busiest_day?.date
                ? moment(res.data?.busiest_day?.date).format("dddd")
                : "--",
              desc: "Current Week",
            },
            {
              icon: "ri-time-fill",
              title: "Busiest Hour",
              value: res.data?.busiest_hour.count,
              hour: res.data?.busiest_hour?.date
                ? res.data?.busiest_hour?.date
                : "-- --",
              desc: "Current Time",
            },
          ]);
          setLoader(false);
        })
        .catch((error) => {
          setPeopleCount([
            {
              id: 1,
              icon: "ri-user-shared-fill",
              title: "People In",
              value: 0,
              desc: "Total People In",
            },
            {
              id: 2,
              icon: "ri-user-unfollow-fill",
              title: "People Exit",
              value: 0,
              desc: "Total People Exit",
            },
            {
              id: 3,
              icon: "ri-team-fill",
              title: "Occupancy",
              value: 0,
              desc: " Total Occupancy",
            },
            {
              id: 4,
              icon: "ri-calendar-event-fill",
              title: "Busiest Day",
              desc: " ",
              day: "--",
              day_letters: "---",
            },
            {
              id: 5,
              icon: "ri-time-fill",
              title: "Busiest Hour",
              desc: " ",
              hour: "----",
            },
          ]);
          setLoader(false);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }, [selected_site]);

  useEffect(() => {
    tempTheme();
  }, []);

  const tempTheme = () => {
    if (document.body) {
      document.body.setAttribute("data-sidebar", "dark");
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItems={dashboardState.breadcrumbItems}
        />
        <Row>
          <Col xl={12}>
            <Row xl={5}>
              <MiniWidgets count={peopleCount} loader={loader} />
            </Row>
          </Col>
        </Row>
        <Row>
          <LineGraph title={""} type={"Dashboard"} data-aos="fade-up" />
        </Row>
        <Row>
          <Col sm={6}>
            <HeatMapChart title={"Visitor Count Matrix"} type={"Dashboard"} />
          </Col>
          <Col sm={6}>
            <BarGraph title={"Visitor Distribution"} type={"Dashboard"} />
          </Col>
        </Row>
        {/* <Row>
          <BarGraph title={"Visitor Distribution"} type={"Dashboard"} />
        </Row> */}
      </Container>
    </div>
  );
}
