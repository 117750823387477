import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { addNewCamera } from "../../../store/cameraSlice";
import { Toastr } from "../../../helpers/Alert";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../helpers/Error";
import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import CreatableSelect from "react-select/creatable";
import InputMask from "react-input-mask";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
function AddSensor(props) {
  // const dispatch=useDispatch();
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [loader, setLoader] = useState({
    loader1: true,
    loader2: true,
    loader3: true,
    loader4: true,
  });
  const [sensorType, setSensorType] = useState([]);
  const dispatch = useDispatch();
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [cameraModel, setCameraModel] = useState([]);
  // const [checkbox, setCheckbox] = useState(false);
  const [ipError, setIpError] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  // const [direction] = useState([
  //   { label: "Yes", value: true },
  //   { label: "No", value: false },
  // ]);
  // const [selectedDirection, setSelectedDirection] = useState({
  //   label: "No",
  //   value: false,
  // });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  useEffect(() => {
    const url2 = "/abaci_count/manufacturers";

    axios
      .get(url2)
      .then((response) => {
        const temp = response.data.map((data) => ({
          ...data,
          label: data.manufacturer_name,
          value: data.manufacturer_name,
        }));
        setSensorType(temp);

        setLoader((state) => ({ ...state, loader3: false }));
      })
      .catch((error) => {
        setLoader((state) => ({ ...state, loader3: false }));

        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, []);

  useEffect(() => {
    setLoader((state) => ({ ...state, loader2: true }));

    if (selectedManufacturer) {
      const urls = `/abaci_count/partnumbers?manufacturer_id=${selectedManufacturer.id}`;
      axios
        .get(urls)
        .then((response) => {
          const model = response.data.map((data) => ({
            ...data,
            label: data.part_number,
            value: data.part_number,
          }));
          setLoader((state) => ({ ...state, loader2: false }));

          setCameraModel(model);
        })
        .catch((error) => {
          setLoader((state) => ({ ...state, loader2: false }));

          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }, [selectedManufacturer]);

  const onSubmit = (data) => {
    let formData = new FormData();

    if (selectedManufacturer.manufacturer_name === "Axis") {
      formData.append("port", data.port);
      formData.append("username", data.username);
      formData.append("password", data.password);
      formData.append("serial_number", data.serial_number);
    }
    formData.append("sensor_name", data.sensor_name);
    // formData.append("is_start_count", checked);
    formData.append("sensor_type", selectedManufacturer.value);
    formData.append("manufacturer", selectedManufacturer.id);
    formData.append("ip_address", ipAddress);
    formData.append("mac_add", data.mac_add);
    formData.append("part_number", selectedModel.id);
    formData.append("site", selected_site.id);
    // formData.append(
    //   "is_site_boundary_sensor",
    //   selectedDirection !== null ? selectedDirection.value : false
    // );

    setwaitingForAxios(true);

    const url = "/abaci_count/sensors";

    axios
      .post(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        dispatch(addNewCamera(response.data));
        // props.tableRef.current.onQueryChange();
        Toastr("success", "New Sensor Added Succesfully");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const onChangeManufacturer = (value) => {
    setSelectedManufacturer(value);
    setSelectedModel(null);
    setCameraModel([]);
  };
  const onChangeModel = (value) => {
    setSelectedModel(value);
  };

  const ipValidator = (value) => {
    if (value !== "___.___.___.___") {
      let temp = value.replace(/_/g, "");
      // Regular expression for IPv4 address
      const ipv4Regex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

      // Regular expression for IPv6 address (simplified)
      const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

      if (ipv4Regex.test(temp || ipv6Regex.test(temp))) {
        // Do something with the valid IPv4 address
        setIpAddress(temp);
        console.log(temp);
        setIpError("");
      } else {
        setIpError("Please Provide a valid IP address");
        // Handle invalid input, show an error message, etc.
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        title={props.title}
        size={"lg"}
        // loader={loader.loader1 || loader.loader2}
      >
        {loader.loader3 ? (
          <div style={{ width: "100%", height: "400px" }}>
            {" "}
            <GraphLoader />
          </div>
        ) : (
          <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
            <div style={{ minHeight: "350px" }}>
              <Label for="">Please Select Manufacturer</Label>
              <FormGroup row style={{ marginBottom: "10px" }}>
                <Col>
                  <CreatableSelect
                    placeholder="Manufacturer"
                    isClearable
                    isValidNewOption={() => false}
                    promptTextCreator={() => false}
                    onChange={(values) => onChangeManufacturer(values)}
                    options={sensorType}
                    isMulti={false}
                    value={selectedManufacturer}
                  />
                </Col>
              </FormGroup>

              {selectedManufacturer !== null && (
                <>
                  <Label for="">Please Select Camera Model</Label>
                  {loader.loader2 && (
                    <Spinner
                      animation="grow"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  )}

                  <FormGroup row style={{ marginBottom: "10px" }}>
                    <Col>
                      <CreatableSelect
                        placeholder="Camera Model"
                        isClearable
                        isValidNewOption={() => false}
                        promptTextCreator={() => false}
                        onChange={(values) => onChangeModel(values)}
                        options={cameraModel}
                        isMulti={false}
                        value={selectedModel}
                      />
                    </Col>
                  </FormGroup>
                </>
              )}

              {selectedManufacturer && selectedModel && (
                <>
                  <FormGroup row style={{ marginBottom: "10px" }}>
                    <Label for="IP Address" sm={3}>
                      IP Address
                    </Label>
                    <Col sm={9}>
                      <InputMask
                        mask="999.999.999.999"
                        placeholder="IP Address"
                        className="form-control"
                        onChange={(event) => ipValidator(event.target.value)}
                      />

                      {ipError && (
                        <span style={{ color: "red" }}>*{ipError}</span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row style={{ marginBottom: "10px" }}>
                    <Label for=" MAC Address" sm={3}>
                      MAC Address
                    </Label>
                    <Col sm={9}>
                      <input
                        onClick={() => trigger("mac_add")}
                        placeholder=" MAC Address"
                        type="text"
                        className="form-control"
                        {...register("mac_add", {
                          required: true,
                          pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
                          onChange: () => {
                            trigger("mac_add");
                          },
                        })}
                      />
                      {errors.mac_add?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                      {errors.mac_add?.type === "pattern" && (
                        <span style={{ color: "red" }}>
                          *Please Provide an Valid MAC Address
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row style={{ marginBottom: "10px" }}>
                    <Label for="Sensor Name" sm={3}>
                      Sensor Name
                    </Label>
                    <Col sm={9}>
                      <input
                        onClick={() => trigger("sensor_name")}
                        placeholder="Sensor Name"
                        type="text"
                        className="form-control"
                        {...register("sensor_name", {
                          required: true,
                          onChange: () => {
                            trigger("sensor_name");
                          },
                        })}
                      />
                      {errors.sensor_name?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                      {errors.sensor_name?.type === "maxLength" && (
                        <span style={{ color: "red" }}>
                          *Camera Name should not exceed 20 characters
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  {selectedManufacturer.value === "Axis" && (
                    <>
                      <FormGroup row style={{ marginBottom: "10px" }}>
                        <Label for=" Port" sm={3}>
                          Port
                        </Label>
                        <Col sm={9}>
                          <input
                            onClick={() => trigger("port")}
                            placeholder=" Port"
                            type="number"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            {...register("port", {
                              onChange: () => {
                                trigger("port");
                              },
                              required: true,
                              max: 65535,
                              pattern:
                                /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi,
                            })}
                            // onChange={(event) => checkIfValidPortnumber(event.target.value)}
                          />
                          {errors.port?.type === "required" && (
                            <span style={{ color: "red" }}>
                              *This field is required
                            </span>
                          )}
                          {errors.port?.type === "max" && (
                            <span style={{ color: "red" }}>
                              *Invalid valid Port
                            </span>
                          )}

                          {errors.port?.type === "pattern" && (
                            <span style={{ color: "red" }}>
                              *Please Provide an Valid Port No
                            </span>
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup row style={{ marginBottom: "10px" }}>
                        <Label for="Serial Number" sm={3}>
                          Serial Number
                        </Label>
                        <Col sm={9}>
                          <input
                            onClick={() => trigger("serial_number")}
                            placeholder="Serial Number"
                            type="text"
                            className="form-control"
                            {...register("serial_number", {
                              required: true,
                              onChange: () => {
                                trigger("serial_number");
                              },
                            })}
                            // onChange={(event) => ipValidator(event.target.value)}
                          />
                          {errors.serial_number?.type === "required" && (
                            <span style={{ color: "red" }}>
                              *This field is required
                            </span>
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup row style={{ marginBottom: "10px" }}>
                        <Label for=" Username" sm={3}>
                          Username
                        </Label>
                        <Col sm={9}>
                          <input
                            autoComplete="off"
                            onClick={() => trigger("username")}
                            placeholder=" Username"
                            type="text"
                            className="form-control"
                            {...register("username", {
                              required: true,
                              onChange: () => {
                                trigger("username");
                              },
                            })}
                          />
                          {errors.username?.type === "required" && (
                            <span style={{ color: "red" }}>
                              *This field is required
                            </span>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row style={{ marginBottom: "10px" }}>
                        <Label for="Password" sm={3}>
                          Password
                        </Label>
                        <Col sm={9}>
                          {/* <OutlinedInput
                            id="outlined-adornment-password"
                            className="form-control"
                            type={showPassword ? "text" : "password"}
                            required
                            color="info"
                            aria-disabled
                            style={{ height: "40px", borderColor: "none" }}
                            onChange={(e) => setNewPassword(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="."
                          /> */}
                          <div className="d-flex">
                            <input
                              onClick={() => trigger("password")}
                              placeholder=" Password"
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              {...register("password", {
                                required: true,
                                onChange: (e) => {
                                  trigger("password");
                                },
                              })}
                              // onFocus={(e) =>
                              //   e.target.removeAttribute("readonly")
                              // }
                              autoComplete="off"
                            />
                            <span class="d-flex justify-content-around align-items-center">
                              <div
                                style={{
                                  position: "absolute",
                                  marginRight: "50px",
                                  cursor: "pointer",
                                }}
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </div>
                            </span>
                          </div>
                          {errors.password?.type === "required" && (
                            <span style={{ color: "red" }}>
                              *This field is required
                            </span>
                          )}
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </>
              )}
            </div>

            {selectedManufacturer && selectedModel && (
              <FormGroup row style={{ marginTop: "20px", color: "#1976D2" }}>
                <Label
                  for="note"
                  style={{ fontSize: "13px", marginBottom: "1px" }}
                >
                  Note : 1. People count in specific site will only begin when
                  the site entry/exit is set to "yes".
                </Label>
                <Label
                  for="note"
                  style={{ fontSize: "13px", paddingLeft: "50px" }}
                >
                  2. People counting in specific zones will only begin when a
                  designated counter is assigned in each zone..
                </Label>
              </FormGroup>
            )}

            <ModalFooter>
              <Button
                onClick={props.toggle}
                type="button"
                className="w-xs waves-effect waves-light me-1"
                style={{ backgroundColor: "#8B8E9F", borderColor: "#8B8E9F" }}
              >
                Cancel
              </Button>
              <Button
                disabled={!selectedManufacturer || !selectedModel || !ipAddress}
                type="submit"
                style={{ backgroundColor: "#1976D2", borderColor: "#1976D2" }}
                className="w-xs waves-effect waves-light me-1"
              >
                {waitingForAxios ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  "Add"
                )}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default AddSensor;
