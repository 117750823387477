import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Button from "@mui/material/Button";
//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import axios from "axios";
import Error from "../../../helpers/Error";
import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import { useSelector } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/not_found_lottie.json";
import SyncIcon from "@mui/icons-material/Sync";
import { payload, post_url } from "../../../helpers/Constants";
export default function HeatMapChart(props) {
  const [error, setError] = useState(null);
  const [state, setState] = useState({ series: [], options: {} });
  const [loader, setLoader] = useState(true);

  const [activeButton, setActiveButton] = useState(0);

  const siteOptions = ["Today", "Last Week", "Last Month"];
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const selected_zone = useSelector((state) => state.zoneSlice.activeZone);
  //graph
  const graphDataHandler = (dataToBeSend) => {
    setState({ series: [], options: {} });
    setLoader(true);
    let url = "";

    let data = {
      ...payload,
      request_type: "post",
      payload: { filter_type: "Last Week" },
    };

    if (props.type === "Dashboard") {
      data = {
        ...data,
        url_params: { site: selected_site?.id },
        url: "/abaci_count/visitor_count_matrix",
      };
    } else {
      data = {
        ...data,
        url_params: { zone: Number(selected_zone) },
        url: "/abaci_count/zone_visitor_count_matrix",
      };
    }
    axios
      .post(post_url, data)
      .then((res) => {
        if (res.data.length !== 0) {
          setState({
            series: res.data,

            options: {
              chart: {
                height: 500,
                type: "heatmap",
              },
              dataLabels: {
                enabled: false,
              },
              colors: ["#1EA7E8"],
            },
          });
          setError(null);
        } else {
          setError("No data to display !");
        }

        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError(Error(error));
        setState({ series: [], options: {} });
      });
  };

  useEffect(() => {
    if (selected_site || selected_zone) {
      graphDataHandler();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_site, selected_zone]);

  //today-week-month-button
  const buttonHandler = (id) => {
    setActiveButton(id);
    setLoader(true);
    setState({ series: [], options: {} });
    graphDataHandler({
      filter_type: siteOptions[id],
    });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Card
          style={{
            minHeight: "650px",
            marginLeft: "-6px",
            paddingRight: "15px",
          }}
        >
          <CardBody>
            <div className="graph-heading-container">
              <h4 className="card-title mb-4">{props.title}</h4>
              {/* <div
                style={{
                  display: "flex",
                  gap: "10px",
                  minWidth: "20%",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  style={{
                    borderRadius: ".25rem",
                    textTransform: "capitalize",
                    color: `${activeButton !== 0 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 0 ? "" : "#1976D2"}`,
                  }}
                  onClick={() => buttonHandler(0)}
                  className="dashboard-button-group-style"
                >
                  Today
                </Button>
                <Button
                  style={{
                    borderRadius: ".25rem",
                    textTransform: "capitalize",
                    color: `${activeButton !== 1 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 1 ? "" : "#1976D2"}`,
                  }}
                  className="dashboard-button-group-style"
                  onClick={() => buttonHandler(1)}
                >
                  This Week
                </Button>
                <Button
                  style={{
                    borderRadius: ".25rem",
                    textTransform: "capitalize",
                    color: `${activeButton !== 2 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 2 ? "" : "#1976D2"}`,
                  }}
                  className="dashboard-button-group-style"
                  onClick={() => buttonHandler(2)}
                >
                  This Month
                </Button>
              </div> */}
              {!loader && (
                <SyncIcon
                  style={{
                    position: "absolute",
                    right: "16px",
                    top: "82px",
                    color: "#677486",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => graphDataHandler()}
                />
              )}
            </div>

            <div id="donut-chart" className="apex-charts mt-3">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="heatmap"
                height="500px"
              />
              {error ? (
                <>
                  <p className="text-center" style={{ marginBottom: "-10px" }}>
                    {error}
                  </p>
                </>
              ) : null}
            </div>

            {loader ? (
              <div className="position_center_class">
                <GraphLoader />
              </div>
            ) : null}

            {selected_site === null && (
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%,-40%)",
                }}
              >
                <>
                  <Player
                    autoplay
                    loop
                    src={lottie}
                    style={{
                      height: "150px",
                      width: "200px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>
                    Oops! No Site have been selected.
                  </span>
                  <p style={{ fontSize: "13px" }} className="text-center">
                    Please select a site.
                  </p>
                </>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
}
