import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { CardBody, Col, Container, Card, Button } from "reactstrap";
import { APP_NAME } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";

import { Toastr } from "../../../helpers/Alert";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import lottie from "../../../assets/lotties/no_search_results.json";
import notfound from "../../../assets/lotties/not_found_lottie.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { exportData } from "../../../helpers/ExportData";
export const SiteReport = () => {
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Site Report", link: "#" },
    ],
  });
  const inputRefs = [useRef(null), useRef(null)];
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const [pageSize, setPageSize] = useState(5);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [loader, setloading] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [date, setDate] = useState({ start_date: "", end_date: "" });
  const [time, setTime] = useState({
    start_time: "",
    end_time: "",
    time_interval: "",
  });
  const tableRef = useRef();
  const totalRecordsCount = useRef(0);
  const urlBackup = useRef();
  const columns = [
    {
      title: "Entry",
      field: "adult_in",
      render: (rowData) => (
        <>
          {rowData.adult_in
            ? rowData.adult_in
            : rowData.adult_in === 0
            ? "0"
            : "-"}
        </>
      ),
    },
    {
      title: "Exit",
      field: "adult_out",
      render: (rowData) => (
        <>
          {rowData.adult_out
            ? rowData.adult_out
            : rowData.adult_out === 0
            ? "0"
            : "-"}
        </>
      ),
    },
    {
      title: "Occupancy",
      field: "live_occ",
      render: (rowData) => <>{rowData.adult_in - rowData.adult_out}</>,
    },
    {
      title: "Time",
      field: "count_time_on_dpu",
      render: (rowData) => (
        <>
          {rowData.count_time_on_dpu
            ? moment(rowData.count_time_on_dpu).format("DD-MM-YYYY hh:mm:ss A")
            : "-"}
        </>
      ),
    },
  ];

  const dateHandler = (value, type) => {
    setDate({ ...date, [type]: value });
  };
  const timeHandler = (value, type) => {
    setTime({ ...time, [type]: value });
  };
  const submitHandler = () => {
    setFilterEnabled(true);
    setloading(true);
    if (date.start_date) {
      tableRef.current.onQueryChange();
    } else {
      Toastr("warning", "Please select Start Date and End Date");
    }
  };
  const cancelFilter = () => {
    if (date.start_date || date.end_date || time.start_time || time.end_time) {
      setFilterEnabled(false);
      setloading(true);
      setDate({ start_date: "", end_date: "" });
      setTime({ start_time: "", end_time: "" });
      tableRef.current.onQueryChange();
    }
  };

  useEffect(() => {
    if (selected_site !== null && date.start_date && date.end_date) {
      tableRef.current.onQueryChange();
      setloading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_site]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Site Report"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          <Col xl={24}>
            <Card style={{ minHeight: "200px" }}>
              <CardBody>
                <div>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Filter
                  </span>
                  <hr style={{ marginTop: "3px" }} />
                  <div className="site_report_filter">
                    <div className="site_report_filter_input">
                      <p
                        style={{
                          color: "#838383",
                          fontZize: "14px",
                          marginBottom: "3px",
                        }}
                      >
                        Start Date
                      </p>
                      <input
                        placeholder="Start Date"
                        type="date"
                        className="form-control "
                        onChange={(event) => {
                          dateHandler(event.target.value, "start_date");
                          inputRefs[0].current.focus();
                        }}
                        value={date.start_date}
                      />
                    </div>
                    <div className="site_report_filter_input">
                      <p
                        style={{
                          color: "#838383",
                          fontZize: "14px",
                          marginBottom: "3px",
                        }}
                      >
                        End Date
                      </p>
                      <input
                        ref={inputRefs[0]}
                        placeholder="End Date"
                        type="date"
                        className="form-control "
                        onChange={(event) =>
                          dateHandler(event.target.value, "end_date")
                        }
                        value={date.end_date}
                      />
                    </div>
                    <div className="site_report_filter_input">
                      <p
                        style={{
                          color: "#838383",
                          fontZize: "14px",
                          marginBottom: "3px",
                        }}
                      >
                        Start Time
                      </p>
                      <input
                        placeholder="Start Time"
                        type="time"
                        className="form-control "
                        onChange={(event) => {
                          timeHandler(event.target.value, "start_time");
                          // inputRefs[1].current.focus();
                        }}
                        value={time.start_time}
                      />
                    </div>
                    <div className="site_report_filter_input">
                      <p
                        style={{
                          color: "#838383",
                          fontZize: "14px",
                          marginBottom: "3px",
                        }}
                      >
                        End Time
                      </p>
                      <input
                        ref={inputRefs[1]}
                        placeholder="End Time"
                        type="time"
                        className="form-control "
                        onChange={(event) =>
                          timeHandler(event.target.value, "end_time")
                        }
                        value={time.end_time}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <Button
                        style={{
                          width: "100px",
                          marginRight: "10px",
                          backgroundColor: "#1976D2",
                          borderColor: "#1976D2",
                        }}
                        onClick={submitHandler}
                        disabled={
                          !selected_site || !date.start_date || !date.end_date
                        }
                      >
                        Apply
                      </Button>
                      <Button
                        style={{
                          width: "100px",
                        }}
                        onClick={cancelFilter}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    style={{ zIndex: "0" }}
                    title=""
                    columns={columns}
                    isLoading={loader}
                    tableRef={tableRef}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <div style={{ height: "300px" }}>
                            {!loader && (
                              <>
                                <Player
                                  autoplay
                                  loop
                                  src={
                                    filterEnabled || searchActive
                                      ? lottie
                                      : notfound
                                  }
                                  style={{
                                    height: "200px",
                                    width: "170px",
                                  }}
                                />
                                <span style={{ fontSize: "13px" }}>
                                  {filterEnabled || searchActive
                                    ? "Oops! No results found !"
                                    : " Oops! No report to dispaly. Please filter with date range"}
                                </span>
                              </>
                            )}
                          </div>
                        ),
                      },
                    }}
                    onChangeRowsPerPage={setPageSize}
                    data={(query) => {
                      // console.log(query)
                      return new Promise((resolve, reject) => {
                        let orderBy = "";
                        let search = "";

                        // let search =''
                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }
                        if (query.search) {
                          setSearchActive(true);
                          setloading(true);
                          let data = query.search.split("-");

                          if (data.length === 3) {
                            let date = moment(query.search).format(
                              "YYYY-DD-MM"
                            );
                            // console.log(date);
                            if (date !== "Invalid date") {
                              // console.log("first");
                              search = date;
                            }
                          } else {
                            search = query.search;
                          }

                          // if (date !== "Invalid date") {
                          //   search = date;
                          // } else {
                          //   search = query.search;
                          // }
                        } else {
                          setSearchActive(false);
                        }

                        let url = `/abaci_count/reports_list_view?limit=${
                          query.pageSize
                        }&offset=${
                          query.pageSize * query.page
                        }&search=${search}${orderBy}`;
                        if (
                          date.start_date !== "" &&
                          date.end_date !== "" &&
                          selected_site !== null
                        ) {
                          url += `&start_date=${date.start_date}&end_date=${date.end_date}&site=${selected_site.id}`;
                        }
                        if (
                          time.start_time !== "" &&
                          time.end_time !== "" &&
                          selected_site !== null
                        ) {
                          url += `&start_time=${time.start_time}&end_time=${time.end_time}&site=${selected_site.id}`;
                        }

                        if (
                          time.start_time !== "" &&
                          time.end_time !== "" &&
                          date.start_date !== "" &&
                          date.end_date !== "" &&
                          selected_site !== null
                        ) {
                          url += `&start_date=${date.start_date}&end_date=${date.end_date}&start_time=${time.start_time}&end_time=${time.end_time}&time_interval=${time.time_interval}&site=${selected_site.id}`;
                        }

                        // setUrlBackup(url);
                        urlBackup.current = url;
                        axios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            // setTotalRecordsCount(response.data?.count);
                            // tableRef.current.dataManager.changePageSize(query.pageSize)
                            // console.log(response.data.results)
                            const TempData = response.data.results;
                            resolve({
                              data: TempData,
                              page:
                                response.data?.results.length === 0
                                  ? 0
                                  : query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            // console.log('rejected')
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      }).finally(() => {
                        setloading(false); // Indicate that the query is complete
                      });
                    }}
                    options={{
                      exportFileName: "Site Report",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      debounceInterval: 500,
                      pageSize: pageSize,
                      exportCsv: async () =>
                        exportData(
                          "csv_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Site Report"
                        ),
                      exportPdf: async () =>
                        exportData(
                          "pdf_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Site Report"
                        ),
                    }}
                    icons={tableIcons}
                  />
                </div>

                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};
