// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser(state, action) {
      state.user = action.payload;
    },
    addNewUser(state, action) {
      state.user = [...state.user, action.payload];
    },
    editUser(state, action) {
      state.user = state.user.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    deleteUser(state, action) {
      // console.log(action.payload);
      state.user = state.user.filter((data) => data.id !== action.payload);
    },
  },
});

export const { addUser, addNewUser, editUser, deleteUser } = userSlice.actions;
export default userSlice.reducer;
