import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import { useDispatch, useSelector } from "react-redux";
import { editSite, selectedSite } from "../../../store/siteSlice";
import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import { isNotEqual } from "../../../helpers/functions";
import { isEndTimeGreaterThanStartTime } from "../../../helpers/timeRange";

function EditSite(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [loader, setLoader] = useState(true);
  const [siteData, setSiteData] = useState({});
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const dispatch = useDispatch();
  const [capacity, setCapacity] = useState({
    threshold: "",
    total_capacity: "",
  });
  const [time, setTime] = useState({ start_time: "", end_time: "" });
  const [timeError, setTimeError] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm();

  const onSubmit = (data) => {
    if (isNotEqual(data, siteData)) {
      let formData = new FormData();
      formData.append("site_name", data.site_name);
      formData.append("rental_space", data.rental_space);
      formData.append("total_capacity", capacity.total_capacity);
      formData.append("threshold", capacity.threshold);
      formData.append("end_time", time.end_time);
      formData.append("start_time", time.start_time);
      formData.append("is_synced_to_cloud", false);

      setwaitingForAxios(true);

      const url = ``;

      axios
        .patch(url, formData)
        .then((response) => {
          setwaitingForAxios(false);
          dispatch(selectedSite(response.data));
          dispatch(editSite(response.data));
          Toastr("success", "Site Updated Succesfully");
          props.toggle();
        })
        .catch((error) => {
          setwaitingForAxios(false);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else {
      props.toggle();
    }
  };

  useEffect(() => {
    const url = `/abaci_count/site/${selected_site?.id}`;
    axios
      .get(url)
      .then((response) => {
        reset(response.data);
        setSiteData(response.data);
        setLoader(false);
        setCapacity({
          threshold: response.data.threshold,
          total_capacity: response.data.total_capacity,
        });
        setTime({
          start_time: response.data.start_time,
          end_time: response.data.end_time,
        });
      })
      .catch((error) => {
        const err_msg = Error(error);
        setLoader(false);
        Toastr("error", err_msg);
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (key, value) => {
    if (key === "total_capacity") {
      if (capacity.threshold !== "" && capacity.threshold > value) {
        setCapacity({ ...capacity, total_capacity: value, threshold: " " });
      } else {
        setCapacity({ ...capacity, total_capacity: value });
      }
    } else {
      setCapacity({ ...capacity, [key]: value });
    }
  };

  const handleTimeInput = (field, value) => {
    setTime((prevData) => {
      const updatedTime = {
        ...prevData,
        [field]: value,
      };

      if (updatedTime.start_time && updatedTime.end_time) {
        if (
          !isEndTimeGreaterThanStartTime(
            updatedTime.start_time,
            updatedTime.end_time
          )
        ) {
          setTimeError("*End time should be greater than start time");
        } else {
          setTimeError(null);
        }
      }

      return updatedTime; // Return the updated state
    });
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        {loader ? (
          <div style={{ width: "100%", height: "400px" }}>
            {" "}
            <GraphLoader />
          </div>
        ) : (
          <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="Site Name" sm={3}>
                Site Name
              </Label>
              <Col sm={9}>
                <input
                  onClick={() => trigger("site_name")}
                  placeholder="Site Name"
                  type="text"
                  className="form-control"
                  {...register("site_name", {
                    required: true,
                    onChange: () => trigger("site_name"),
                  })}
                />
                {errors.site_name?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
              </Col>
            </FormGroup>

            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="Total Area" sm={3}>
                Total Area(sqm)
              </Label>
              <Col sm={9}>
                <input
                  onClick={() => trigger("rental_space")}
                  placeholder="Total Area "
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  {...register("rental_space", {
                    required: true,
                    onChange: () => trigger("rental_space"),
                  })}
                />
                {errors.rental_space?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
              </Col>
            </FormGroup>

            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for=" Total Capacity(Person)" sm={3}>
                Total Capacity(Person)
              </Label>
              <Col sm={9}>
                <input
                  onClick={() => trigger("total_capacity")}
                  placeholder=" Total Capacity"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  value={capacity.total_capacity}
                  {...register("total_capacity", {
                    required: true,
                    // min: capacity.total_capacity,
                    onChange: (e) => {
                      trigger("total_capacity");
                      handleInput("total_capacity", e.target.value);
                    },
                  })}
                />
                {errors.total_capacity?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
                {/* {errors.total_capacity?.type === "min" && (
                  <span style={{ color: "red" }}>
                    {`*Please provide mimimum ${capacity.total_capacity}`}{" "}
                  </span>
                )} */}
              </Col>
            </FormGroup>

            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for=" Allowed Capacity(Person)" sm={3}>
                Allowed Capacity(Person)
              </Label>
              <Col sm={9}>
                <input
                  onClick={() => trigger("threshold")}
                  placeholder="Allowed Capacity"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="form-control mt-2"
                  value={capacity.threshold}
                  {...register("threshold", {
                    required: true,
                    max: capacity.total_capacity,
                    onChange: (e) => {
                      handleInput("threshold", e.target.value);
                      trigger("threshold");
                    },
                  })}
                />
                {errors.threshold?.type === "max" && (
                  <span style={{ color: "red" }}>
                    *Allowed capacity should be less than total capacity
                  </span>
                )}
                {errors.threshold?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
              </Col>
            </FormGroup>

            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="Start Time" sm={3}>
                Start Time
              </Label>
              <Col sm={9}>
                <input
                  onClick={() => trigger("start_time")}
                  placeholder="Start Time"
                  type="time"
                  className="form-control"
                  value={time.start_time}
                  {...register("start_time", {
                    required: true,
                    onChange: (e) => {
                      trigger("start_time");
                      handleTimeInput("start_time", e.target.value);
                    },
                  })}
                />
                {errors.start_time?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="End Time" sm={3}>
                End Time
              </Label>
              <Col sm={9}>
                <input
                  placeholder="End Time"
                  type="time"
                  className="form-control"
                  value={time.end_time}
                  {...register("end_time", {
                    required: true,
                    onChange: (e) => {
                      trigger("end_time");
                      handleTimeInput("end_time", e.target.value);
                    },
                  })}
                />
                {errors.end_time?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
                {timeError && <span style={{ color: "red" }}>{timeError}</span>}
              </Col>
            </FormGroup>

            <ModalFooter>
              <Button
                type="button"
                className="w-xs waves-effect waves-light me-1"
                onClick={props.toggle}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{ backgroundColor: "#1976D2", borderColor: "#1976D2" }}
                className="w-xs waves-effect waves-light me-1"
                // onClick={() => {
                //     onSubmit();
                // }}
                disabled={!isValid || timeError !== null}
              >
                {waitingForAxios ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  "Update"
                )}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default EditSite;
