import React, { useEffect, useState } from "react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "../../containers/Pages/Error/Unauthorized";
import { authProtectedRoutes } from "../../routes/index";
import axios from "axios";
import Error from "../../helpers/Error";
import { Toastr } from "../../helpers/Alert";

export default function SidebarContent(props) {
  const profile = useSelector((state) => state.authSlice.profile);

  const location = useLocation();
  const [zone, setZone] = useState([]);
  const [loader, setLoader] = useState([]);
  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    const zoneUrl = `/abaci_count/zones?limited=${true}`;
    axios
      .get(zoneUrl)
      .then((response) => {
        setZone(response.data);

        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);

        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, []);

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{"Menu"}</li>
          {authProtectedRoutes.map((item, index) => {
            if (
              item.sidebar_presence &&
              item.appType?.includes(profile?.user_type)
            ) {
              return (
                <li key={index}>
                  {item.submenu && item.zone ? (
                    <>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i
                          className={item.icon_class}
                          style={{
                            color:
                              location.pathname == item.path
                                ? "white"
                                : "#8590a5",
                          }}
                        ></i>

                        <span
                          className="ms-1"
                          style={{
                            color:
                              location.pathname == item.path
                                ? "white"
                                : "#8590a5",
                          }}
                        >
                          {item.name}
                        </span>
                      </Link>
                      <ul className="sub-menu">
                        {zone.length !== 0 &&
                          zone.map((data, index) => (
                            <li key={index}>
                              <Link
                                to={`/zonedetails/${data.id}`}
                                className="waves-effect"
                              >
                                <i
                                  className={data.icon_class}
                                  style={{
                                    color:
                                      location.pathname ==
                                      `/zonedetails/${data.id}`
                                        ? "white"
                                        : "#8590a5",
                                  }}
                                ></i>

                                <span
                                  className="ms-1"
                                  style={{
                                    color:
                                      location.pathname ==
                                      `/zonedetails/${data.id}`
                                        ? "white"
                                        : "#8590a5",
                                  }}
                                >
                                  {data.zone_name ? data.zone_name : "No Data"}
                                </span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </>
                  ) : item.submenu ? (
                    <>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i
                          className={item.icon_class}
                          style={{
                            color:
                              location.pathname == item.path
                                ? "white"
                                : "#8590a5",
                          }}
                        ></i>

                        <span
                          className="ms-1"
                          style={{
                            color:
                              location.pathname == item.path
                                ? "white"
                                : "#8590a5",
                          }}
                        >
                          {item.name}
                        </span>
                      </Link>
                      <ul className="sub-menu">
                        {item.submenu_components.map((data, index) => (
                          <li key={index}>
                            <Link to={data.path} className="waves-effect">
                              <i
                                className={data.icon_class}
                                style={{
                                  color:
                                    location.pathname == data.path
                                      ? "white"
                                      : "#8590a5",
                                }}
                              ></i>

                              <span
                                className="ms-1"
                                style={{
                                  color:
                                    location.pathname == data.path
                                      ? "white"
                                      : "#8590a5",
                                }}
                              >
                                {data.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <Link to={item.path} className="waves-effect">
                        <i
                          className={item.icon_class}
                          style={{
                            color:
                              location.pathname == item.path
                                ? "white"
                                : "#8590a5",
                          }}
                        ></i>

                        <span
                          className="ms-1"
                          style={{
                            color:
                              location.pathname == item.path
                                ? "white"
                                : "#8590a5",
                          }}
                        >
                          {item.name}
                        </span>
                      </Link>
                    </>
                  )}
                </li>
              );
            }
          })}
          <li></li>
        </ul>
      </div>
    </React.Fragment>
  );
}
