import React from "react";
import { DropdownItem } from "reactstrap";
// import EditOutlined from "@mui/icons-material/EditOutlined";

function DropDownItem({ data, handleSelectSite, index }) {
  // const [mouseOnTitle, setMouseOnTitle] = useState(false);
  // const [mouseOnEdit, setMouseOnEdit] = useState(false);
  return (
    <DropdownItem
      key={data.id}
      onClick={() => handleSelectSite(data)}
      // onMouseEnter={() => setMouseOnTitle(true)}
      // onMouseLeave={() => setMouseOnTitle(false)}
    >
      <div className="d-flex justify-content-between" key={data.id}>
        <p
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
            marginBottom: "-1px",
          }}
        >
          {data.site_name}
        </p>

        {/* {mouseOnTitle ? (
          <EditOutlined
            style={{
              fontSize: "14px",
              cursor: "pointer",
              marginTop: "2px",
            }}
            color={mouseOnEdit ? "action" : "disabled"}
            onClick={() => editModalToggle(data)}
          />
        ) : null} */}
      </div>
    </DropdownItem>
  );
}

export default DropDownItem;
