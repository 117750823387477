import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { CardBody, Col, Container, Card, Button } from "reactstrap";
import { APP_NAME, payload, post_url } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { useDispatch, useSelector } from "react-redux";
import { addCamera } from "../../../store/cameraSlice";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import { useNavigate } from "react-router";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/no_search_results.json";
import notfound from "../../../assets/lotties/not_found_lottie.json";
import axios from "axios";
import AddSensor from "./AddSensor";

export const Sensor = () => {
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const camera = useSelector((state) => state.cameraSlice.camera);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Sensor Details ", link: "#" },
    ],
  });
  const [cameraData, setCameraData] = useState([]);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [findCameraModalShow, setFindCameraModalShow] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (selected_site !== null) {
  //     setLoader(true);
  //     const data = {
  //       url: `/abaci_count/sensors`,
  //       site: selected_site.id,
  //       ...payload,
  //     };
  //     axios
  //       .post(post_url, data)
  //       .then((response) => {
  //         dispatch(addCamera(response.data));
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         const err_msg = Error(error);
  //         Toastr("error", err_msg);
  //       });
  //   }

  //   return () => {
  //     dispatch(addCamera([]));
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selected_site]);

  // useEffect(() => {
  //   if (camera !== null) {
  //     const temp = camera.map((data, index) => ({
  //       ...data,
  //       sl_no: index + 1,
  //       sensor_name: data?.sensor_name ? data.sensor_name : "-",
  //       zone_name: data?.zone?.zone_name ? data.zone.zone_name : "-",
  //       serial_no: data?.serial_number ? data.serial_number : "-",
  //       camera_model: data?.part_number?.part_number
  //         ? data.part_number.part_number
  //         : "-",
  //       manufacturer_name: data?.manufacturer?.manufacturer_name
  //         ? data.manufacturer.manufacturer_name
  //         : "-",
  //       address: data?.ip_address ? data.ip_address : "-",
  //       username: data?.username ? data.username : "-",
  //       password: data?.password ? data.password : "-",
  //       port: data?.port ? data.port : "-",
  //       no_of_counters: data?.no_of_counters ? data.no_of_counters : "-",
  //       // is_site_boundary_sensor: data?.is_site_boundary_sensor ? "Yes" : "No",
  //     }));

  //     setCameraData(temp);
  //   }
  // }, [camera]);

  const addCameraModalToggle = () => {
    setModalShow(!modalShow);
  };

  const columns = [
    { title: "SL No", field: "sl_no" },
    { title: "Sensor Name", field: "sensor_name" },
    { title: "Manufacturer", field: "manufacturer_name" },
    { title: "Camera Model", field: "camera_model" },
    { title: "Serial Number", field: "serial_no" },
    { title: "IP Address", field: "ip_address" },
    { title: "Mac Address", field: "mac_add" },
    { title: "Port", field: "port" },
    { title: "Username", field: "username" },
    // { title: "Boundary Sensor", field: "is_site_boundary_sensor" },
    { title: "Status", field: "status" },
  ];

  const FindCameraModalToggle = () => {
    setFindCameraModalShow(!findCameraModalShow);
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  };

  return (
    <React.Fragment>
      {modalShow && (
        <AddSensor
          title={"Add Sensor"}
          toggle={addCameraModalToggle}
          isOpen={modalShow}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Sensor Details"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>
          {/* <div
            className="disappearing-button-container-page-top-right"
            style={{ marginRight: "-60px" }}
          >
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#1976D2",
                borderColor: "#1976D2",
              }}
              type="button"
              className="w-xs waves-effect waves-light me-1"
              onClick={FindCameraModalToggle}
            >
              Find Sensor
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#1976D2",
                borderColor: "#1976D2",
              }}
              type="button"
              className="w-xs waves-effect waves-light me-1 "
              onClick={() => addCameraModalToggle()}
            >
              Add Sensor
            </Button>
          </div> */}
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    style={{ zIndex: "0" }}
                    title=""
                    columns={columns}
                    // data={cameraData}
                    isLoading={loader}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <div style={{ height: "300px" }}>
                            {!loader && (
                              <>
                                {" "}
                                <Player
                                  autoplay
                                  loop
                                  src={
                                    searchActive || filterEnabled
                                      ? lottie
                                      : notfound
                                  }
                                  style={{
                                    height: "200px",
                                    width: "170px",
                                  }}
                                />
                                <span style={{ fontSize: "13px" }}>
                                  {searchActive || filterEnabled
                                    ? "Oops! No results found !"
                                    : " Oops! No Sensor have been added. Please add your Sensor to begin counting"}
                                </span>
                              </>
                            )}
                          </div>
                        ),
                      },
                    }}
                    onSearchChange={handleSearch}
                    actions={[
                      // {
                      //   icon: FilterList,
                      //   tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                      //   isFreeAction: true,
                      //   onClick: (event) => { setFilterEnabled(state => !state) }
                      // },

                      // {
                      //   icon: RemoveRedEyeIcon,
                      //   tooltip: "View Details",
                      //   // onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                      // },
                      {
                        icon: FilterListIcon,
                        tooltip: filterEnabled
                          ? "Disable Filter"
                          : "Enable Filter",
                        isFreeAction: true,
                        onClick: (event) => {
                          setFilterEnabled((state) => !state);
                        },
                      },

                      // {
                      //   icon: Edit,
                      //   tooltip: "Edit Sensor",
                      //   onClick: (event, rowData) => {
                      //     // setEditClientModalShow(true)
                      //     setItemToBeEdited(rowData);
                      //     editModalToggle();
                      //   },
                      // },

                      // (rowData) => ({
                      //   icon: Unlock,
                      //   tooltip: "Enable Sensor",
                      //   onClick: (event, rowData) => {
                      //     Swal.fire({
                      //       title: "Are you sure?",

                      //       icon: "info",
                      //       showCancelButton: true,
                      //       confirmButtonColor: "#3085d6",
                      //       cancelButtonColor: "#d33",
                      //       confirmButtonText: "Yes, Enable it!",
                      //     }).then((result) => {
                      //       if (result.isConfirmed) {
                      //         UpdateHandler(rowData.id, "Active");
                      //         // deleteClientData(rowData?.id)
                      //       }
                      //     });
                      //     // setDeleteModal({ id: rowData?.id, toggle: true });
                      //   },
                      //   hidden: rowData.status === "Active",
                      // }),
                      // (rowData) => ({
                      //   icon: LockIcon,
                      //   tooltip: "Disable Sensor",
                      //   onClick: (event, rowData) => {
                      //     Swal.fire({
                      //       title: "Are you sure?",
                      //       icon: "info",
                      //       showCancelButton: true,
                      //       confirmButtonColor: "#3085d6",
                      //       cancelButtonColor: "#d33",
                      //       confirmButtonText: "Yes, Disable it!",
                      //     }).then((result) => {
                      //       if (result.isConfirmed) {
                      //         UpdateHandler(rowData.id, "Disabled");
                      //         // deleteClientData(rowData?.id)
                      //       }
                      //     });
                      //     // setDeleteModal({ id: rowData?.id, toggle: true });
                      //   },
                      //   hidden: rowData.status === "Disabled",
                      // }),
                      // (rowData) => ({
                      //   icon: DeleteOutlineIcon,
                      //   tooltip: "Delete Sensor",
                      //   onClick: (event, rowData) => {
                      //     Swal.fire({
                      //       title: "Are you sure?",
                      //       text: "You won't be able to revert this!",
                      //       icon: "warning",
                      //       showCancelButton: true,
                      //       confirmButtonColor: "#3085d6",
                      //       cancelButtonColor: "#d33",
                      //       confirmButtonText: "Yes, Delete it!",
                      //     }).then((result) => {
                      //       if (result.isConfirmed) {
                      //         UpdateHandler(rowData.id, "Deleted");
                      //         // deleteSensor(rowData?.id)
                      //       }
                      //     });
                      //     // setDeleteModal({ id: rowData?.id, toggle: true });
                      //   },
                      // }),
                    ]}
                    onRowClick={(event, rowData) =>
                      navigate(`/sensordetails/${rowData.id}`)
                    }
                    options={{
                      exportFileName: "Sensor",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      filtering: filterEnabled,
                      debounceInterval: 500,
                    }}
                    icons={tableIcons}
                  />
                </div>

                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};
