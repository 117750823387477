import { useEffect, useState } from "react";
// import { publicAxios, authAxios } from "./axiosInstance";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "./components/AbaciLoader/AbaciLoader";
import { useNavigate } from "react-router-dom";
import { setLogin, setLogOut } from "./store/auth";
// import Unauthorized from "./containers/Pages/Error/Unauthorized"
import ErrorPage from "./containers/Pages/Error";
import axios from "axios";
import Unauthorized from "./containers/Pages/Error/Unauthorized";
import { authAxios } from "./axiosInstance";
import { payload, post_url } from "./helpers/Constants";

export default function PrivateRoute(props) {
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(location)
  //   const url = "users_api/profile";
  //       axios
  //         .get(url)
  //         .then((response) => {
  //           if (response.data.user_status === "Activated") {
  //             dispatch(setLogin({user:response.data}))
  //             navigate("/dashboard");
  //           }
  //           else {
  //             Cookies.remove("login_token");
  //             dispatch(setLogOut())
  //             navigate("/login");
  //           }
  //         })
  //         .catch((error) => {
  //           if (!error.response) {
  //             setError(502);
  //           } else {
  //             const errorStatusTemp = error.response.status;
  //             if (errorStatusTemp === 401 || errorStatusTemp=== 403) {
  //               Cookies.remove("login_token");
  //               dispatch(setLogOut())
  //               navigate("/login");
  //             } else {
  //               setError(errorStatusTemp)
  //             }
  //           }
  //         })
  // }, [location])

  useEffect(() => {
    if (auth.loggedIn === null) {
      const token = Cookies.get("login_token");
      if (typeof token === "undefined") {
        dispatch(setLogOut());
        navigate("/login");
      } else {
        const url = "users_api/profile";
        authAxios
          .post(post_url, { ...payload, url: url, token: token })
          .then((response) => {
            if (response.data.user_status === "Activated") {
              dispatch(setLogin({ user: response.data }));
              // navigate("/dashboard");
            } else {
              Cookies.remove("login_token");
              localStorage.removeItem("login_token");
              dispatch(setLogOut());
              navigate("/login");
            }
          })
          .catch((error) => {
            if (
              error.message === "timeout of 5000ms exceeded" ||
              error.message === "Network Error"
            ) {
              setError(500);
            } else {
              const errorStatusTemp = error.response.status;
              if (errorStatusTemp === 401 || errorStatusTemp === 403) {
                Cookies.remove("login_token");
                localStorage.removeItem("login_token");
                dispatch(setLogOut());
                navigate("/login");
              } else {
                setError(errorStatusTemp);
              }
            }
          });
      }
    }
  });

  if (error !== null) {
    return <ErrorPage error={error} />;
    // } else if (auth.loggedIn) {
    // return <></>
    // if (props.allowedTo.includes(auth.profile.user_class)) {
    // return <>{props.children}</>;
    // }
    // else{
    //   return <Unauthorized />
    // }
  } else if (auth.loggedIn && auth.profile !== null) {
    if (props.appType?.includes(auth.profile?.user_type)) {
      return <>{props.children}</>;
    } else {
      return <Unauthorized />;
    }
  } else {
    return <AbaciLoader />;
  }
}
