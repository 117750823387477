import React, { useEffect } from "react";
import Modal from "../../../components/Modal";
import axios from "axios";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import { useDispatch } from "react-redux";
import { readNotification } from "../../../store/notificationsSlice";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

function NotificationModal(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readNotification());

    if (props.item) {
      const url = `/abaci_count/alerts/${props.item.id}`;
      axios
        .patch(url, { is_read: true })
        .then((response) => {
          props.tableRef.current.onQueryChange();
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }, [props]);

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        title={props.title}
        NotCentered
        size={"xl"}
      >
        <div>
          {/* <span
            className="mt-1"
            style={{ fontWeight: "bold", fontSize: "15px" }}
          >
            {props.item.alert_type === "Connection Alert" && (
              <CameraAltIcon
                style={{
                  fontSize: "20px",

                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />
            )}
            {props.item.alert_type}
          </span> */}
          <div
            style={{
              width: "100%",
              minHeight: "90px",
              backgroundColor: "#F1F5F7",
              border: "1px solid #C0C0C0",
              fontSize: "15px",
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            {props.item.alert_message}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NotificationModal;
