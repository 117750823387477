import React from "react";
// { Component }
import { Col, Card, CardBody } from "reactstrap";
import BusyDay from "../../../assets/svg/busyday.svg";
import Busytime from "../../../assets/svg/busy time.svg";
import Peoplein from "../../../assets/svg/peoplein.svg";
import Peopleout from "../../../assets/svg/peopleout.svg";
import Occupancy from "../../../assets/svg/occ.svg";
import GraphLoaderSmall from "../../../components/GraphLoaderSmall/GraphLoaderSmall";

export default function MiniWidgets(props) {
  return (
    <React.Fragment>
      {props.count.map((data, key) => (
        <Col key={key}>
          <Card style={{ minHeight: "130px" }}>
            {props.loader ? (
              <GraphLoaderSmall />
            ) : (
              <>
                {" "}
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-13 mb-2">
                        {data.title}
                      </p>

                      {data.title === "Busiest Day" ? (
                        <span
                          className="mt-3"
                          style={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          {data.day && data.day_letters + " " + data.day}
                        </span>
                      ) : data.title === "Busiest Hour" ? (
                        <span
                          className="mt-3"
                          style={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          {data.hour && data.hour}
                        </span>
                      ) : (
                        <h5 className="mb-0">{data.value}</h5>
                      )}

                      {/* <p>{data?.day && data.day}</p> */}
                    </div>
                    <div className="text-primary" style={{ marginTop: "-8px" }}>
                      <img
                        src={
                          data.title === "Busiest Hour"
                            ? Busytime
                            : data.title === "Busiest Day"
                            ? BusyDay
                            : data.title === "People Exit"
                            ? Peopleout
                            : data.title === "Occupancy"
                            ? Occupancy
                            : Peoplein
                        }
                        alt=""
                      ></img>
                    </div>
                  </div>
                </CardBody>
                <CardBody className=" py-3" style={{ marginTop: "-12px" }}>
                  <div className="text-truncate" style={{ color: "#e4e4e4" }}>
                    {/* <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> {data.rate}</span> */}
                    <span className="text-muted font-size-10 ">
                      {data.desc ? data.desc : ""}
                    </span>
                  </div>
                </CardBody>
              </>
            )}
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
}
