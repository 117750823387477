import React from "react";
import { ModalFooter, Button } from "reactstrap";
import Modals from "../../../components/Modal";
function TermsAndCondition(props) {
  return (
    <Modals
      size={props.size}
      title={"FOGwatch Terms and Conditions"}
      toggle={props.toggle}
      isOpen={props.isOpen}
    >
      <p className="terms_p">INTRODUCTION</p>
      <p>
        FOGwatch is to provide an overview of a complete system to manage grease
        trap installation, cleaning, waste collection, transport, and discharge
        from the source (Waste generator) to the final destination (treatment
        plant) at Envirol. The proposed system is designed to provide easy
        digital access to all involved stakeholders under a unified digital
        platform.
      </p>
      <p style={{ marginBottom: "5px" }}>
        User = the grease trap cleaning company{" "}
      </p>
      <p>Service Provider = FOGwatch</p>
      <p className="terms_p">SERVICE</p>
      <p>
        FOGwatch has launched a digital platform for managing grease traps, in
        cooperation with Dubai Municipality, this service is designed to provide
        convenience to the food, beverage, staff accommodation, malls and the
        hospitality sector. The digital scale will increase the productivity of
        the organization and will reduce manpower resources. This digitization
        will provide many benefits to the users such as; Increase customer
        satisfaction, Drives Data-Based Insights, Enable Software Monetization,
        Enable High-Quality User Experience, Encourage Collaboration and Improve
        Communication, Increase Flexibility, and Limits Human Error. The
        FOGwatch is the ultimate solution to your waste management.
      </p>
      <p>
        FOG watch service is a digital platform, for any inconvenience users may
        allow sharing their feedback at support
        <a href="https://envirol.ae/">@envirol.ae</a>
      </p>
      <p className="terms_p">USER GUIDELINES</p>
      <p style={{ fontWeight: "bold" }}>Understanding</p>
      <p>
        The software is designed under expert supervision where users need to
        follow simple steps to register. It is very easy to understand, and the
        user can also monitor their transactions. FOGwatch Training Specialists
        will assist and train you in procedures and manuals, providing physical
        and online training sessions to their users. The users are responsible
        for all the training material provided to them and the service provider
        is not responsible/ liable for any arising uncertainties or
        misunderstandings in the future.
      </p>
      <p style={{ fontWeight: "bold" }}>Restriction</p>
      <p>
        Users are prohibited from taking such actions, allowing Service Provider
        to claim damages in case of any breach such as; Misbehavior,
        inappropriate usernames, multiple user IDs, sharing your account
        details, providing services on your ID to outside companies, using
        social media or any communication platform (Facebook, Instagram or
        Posting marketing materials on LinkedIn). The service provider obtains a
        license and permission from the government authorities for this service,
        the user cannot claim any damages from the approving authorities. All
        users may train their team and subordinates about the rules and
        regulations of FOGwatch procedure, the user and subordinates are not
        allowed to claim any service from service provider through referral
        screenshot approval. The user will submit the Service Request No. at the
        time they entering the Envirol Gate.To obtain the service, the user must
        maintain the required balance in the account, in the event that there is
        not enough balance in the user's account, he will not be entitled to
        obtain any service.
      </p>
      <p className="terms_p">MODE OF PAYMENT</p>
      <p>
        Prices for FOGwatch Software Service(s) purchased hereunder shall be
        agreed to from time to time. Such prices do include any taxes, fees, or
        other levies. Any such items (except taxes on Company's income) will be
        added to the purchase price. Company may at any time change Software
        pricing without notice or liability. Users will receive an
        acknowledgment of rescheduling on the program, and any discount and
        offer will also be updated on the user ID. All users can make payments
        through bank transfers and credit/debit cards. The user may not allow
        his credit and debit card information to be saved on the software, for
        security purposes this is not permitted. The initial purchase invoice
        will be digital, and the balance will be reflected on the user’s
        dashboard. Users may allow to monitor their usage 24/7 and top up as per
        their requirements.
      </p>
      <p className="terms_p">FEES AND PAYMENT INVOICING TERMS</p>
      <p>
        Invoice generation is done at the end of the month based on daily
        discharge collection. Invoice and Statement of Account (SOA) will be sent
        to the registered email address of the client. Electronic invoices
        provided by "Supplier" as per UAE Federal Tax Authority standards. In
        case of discrepancy, the “Client” must notify the “Supplier” in writing
        within 15 days from the invoice date. Change using the official
        communication channel. In case of refund, the client should send an
        email (refund@envirol.ae) mentioning the account details and its
        supporting documents. Once reconciled, the refund will be credited after
        15 days of submission.
      </p>
      <p className="terms_p">PAYMENT OF FEES TERMS</p>
      <p>
        Users must pay advance payment or recharge through the following payment
        channels stating, Users code or their FOG Watch code name which can be
        used within 6 months from date of recharge otherwise it will be
        forfeited and subject to renewal fee of 2.52% of the available recharge
        balance:
        <ol>
          <li>Bank transfer through the account details below:</li>
          <span>Bank Name: Commercial Bank of Dubai</span>
          <br />
          <span>Branch Name: Head Office Account</span>
          <br />
          <span>Title: Al Serkal Envirol BAN No: AE620230000001001111051</span>
          <br />
          <span>Currency:AED</span>
          <li>
            Credit Card - Recharge through the portal (fogwatch.envirol.ae) and
            subject to 2.52% of the recharge amount with a minimum of AED 1,000
            and a maximum of AED 100,000.
          </li>
        </ol>
      </p>
      <p className="terms_p">REFUND</p>
      <p>
        Company will provide full refunds for Software Service(s) in accordance
        with the applicable Company Return Policy. The Company will also offset
        the refund through credit points, the point will increase your credit
        balance and the user may allow the use of the point at the time of
        payment. Users need to provide a legitimate reason which makes the
        eligibility of the refund, in such below-mentioned terms & conditions
        user may entitle to get a refund. The refund request will take into
        account whether the documents are valid and verified.
      </p>
      <ol>
        <li>Company bankruptcy</li>
        <li>Legal Dispute</li>
        <li>Financial Uncertainties</li>
        <li>Medical Issues/ Death </li>
      </ol>
      <p className="terms_p">UTILIZATION / EXPIRY OF </p>
      <p className="terms_p">CREDIT </p>
      <p>
        FOGwatch subscription is valid for 6 months, if the user cannot use his
        balance in this period, the account will expire automatically. To
        re-activate the account, the user needs to pay a 2.5% renewal fee, which
        is calculated on his closing balance.
      </p>
      <p className="terms_p"> COMPLIANCE</p>
      <p>
        Under the governance of Dubai Municipality Authority, Envirol develops
        Software FOGwatch, compliance with the guidelines that are applicable to
        the user and the service provider, in case of violation, the internal
        committee will investigate the facts and share the report with the
        regulatory authority, the terms and conditions of FOGwatch have been
        approved by the regulatory authority.
      </p>
      <p className="terms_p"> INSPECTION</p>
      <p>
        An inspection of waste management procedures and practices can reveal
        the knowledge, attitudes, and practices of the staff as well as
        information on actual adherence to waste management procedures.
        Information from audits will help identify the current gaps in waste
        management practices and help determine how to improve them.
      </p>
      <p>
        The Service Provider will conduct inspection sessions to ensure
        compliance policies are adhered to, and the User will give permission to
        the Auditors/Inspection Team, only authorized staff is permitted to
        conduct this inspection, without identity verification user will not
        grant permission. As per the agreed terms, users will allow to access
        particular records, which are pertaining to the requirements of
        inspection, such as; Responsibilities & Duties, Record Maintenance,
        Equipment Handling Guidelines, Limitations, Cleaning Procedures &
        Protocols, Staff Safety & Transportation Guidelines.
      </p>
      <p className="terms_p"> PENALTIES</p>
      <p>
        The users and subordinates are responsible for all the training material
        provided to them and the service provider is not responsible/ liable for
        any arising uncertainties or misunderstandings in the future. The user
        cannot claim for any service after the training session being given to
        the authorities.
      </p>
      <p className="terms_p"> INTELLECTUAL PROPERTY</p>
      <p>
        The Materials displayed on this software, including without limitation
        all editorial materials, information, photographs, illustrations,
        artwork and other graphic materials, and names, logos, and trademarks,
        are solely our property and may be protected, at our sole discretion by
        copyrights, trade mark, and other intellectual property laws. Any such
        content may be displayed and printed solely for your personal,
        noncommercial use, provided that any copyright notice on such a display
        or page is not removed. You agree not to reproduce, retransmit,
        distribute, disseminate, sell, publish broadcast or circulate any such
        material to any third party with our express prior written consent. Save
        for the above, and unless expressly granted, we do not grant any license
        or rights in or assign all or part of, its intellectual property rights
        in the content or application incorporated into this software or in the
        user interface of this website. You may be held accountable for damages
        (including costs and attorneys’ fees) for misrepresentation or bad-
        faith claims on the infringement of any Content found on and/or through
        Service on your copyright.
      </p>
      <p className="terms_p"> MAINTENANCE</p>
      <p>
        FOGwatch is a highly efficient software, developed by a team of experts,
        and monitored by the regulatory authority. Our customer service and
        maintenance team are available to assist the users 24/7. Software
        updates will be notified to the user from time to time.
      </p>

      <p>
        The expected duration of software maintenance and updates is 48 hours,
        during which customers are allowed to use the services via Envirol’s
        policies in addition, in the event of any force majeure, the service
        provider shall not be liable for failure to perform due to reasons
        beyond its control, including fire, flood, earthquake, explosion,
        accident, acts of public enemy, war, rebellion, insurrection, sabotage,
        epidemic disease, quarantine, labor disputes or shortages, acts of God
        or government, etc. any such event of force majeure affecting to the
        service provider, or any judicial action. After the training
        certification, the user may be held responsible for following the
        service provider guidelines in case of any negligence committed by the
        user, therefore not accepted by the service provider in any way.
      </p>
      <p className="terms_p"> LICENSE</p>
      <p>
        FOGwatch obtains and keeps current all necessary licenses, approvals,
        permits, and authorizations required by applicable laws to provide the
        Work. FOG watch is registered with the approval authorities, and it’s
        completely secure and protected.
      </p>
      <p className="terms_p"> PAYMENT SECURITY</p>
      <p>
        The security of online payment or bank transfer is not related to the
        service provider, in terms of any technical error that users may allow
        to enquire from the authorizer or broker. The FOGwatch payment method is
        associated with a third-party payment channel, and the user is
        prohibited to save any financial information on our platform. The online
        payment through Credit/Debit Card will be reflected in the user's
        account in 48 hours, the user will not submit any request for the claim
        in a tentative duration. The bank transfer payment will also be
        reflected in the user's account after 48 hours, and any information will
        be displayed on the software dashboard after the payment is cleared.
      </p>
      <p className="terms_p"> GOVERNING LAW</p>
      <p>
        Any controversy arising out of or in relation to it shall be governed by
        the law of UAE Sharia / DIFC LAW (conflicts of law’s provisions
        excepted), the parties hereby submit to the jurisdiction of the state
        and federal courts of “United Arab Emirates” which shall have exclusive
        jurisdiction over all controversies in connection herewith. Both parties
        notify each other about the legal proceeding on valid address or contact
        details, without prior notification, the parties will not execute any
        legal obligation to each other, however in terms of any serious
        violation from the user, the service provider has a right to terminate
        his service on immediate basis.
      </p>
      <p>
        Terms and Conditions are subject to changes/ modifications at any time
        applied by the service provider.
      </p>
      <p>
        All disputes arising out of or in connection with this agreement,
        including any question regarding its existence, validity or termination,
        must, unless amicably settled between the Parties, be finally settled by
        arbitration according to the Rules of Arbitration of Dubai, UAE by one
        arbitrator in accordance with the Rules. The arbitration proceedings
        must be confidential and must be conducted in English. The award is
        final and binding on the Parties and enforceable in any court of
        competent jurisdiction
      </p>
      <p className="terms_p"> CONFIDENTIALITY</p>
      <p>
        Neither Party shall under any circumstances disclose to any third
        parties any Information without the prior written permission of the
        service provider. The Parties shall use the Information only for purpose
        of performing their obligations under terms & conditions guidelines.
      </p>
      <p>
        Without prejudice to the generality of the foregoing, both parties agree
        to protect the confidentiality of the other Party’s Information at least
        with the same degree of care as it exercises with respect to its own
        confidential information and trade secrets.
      </p>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className="w-xs waves-effect waves-light me-1"
          onClick={props.toggle}
        >
          OK
        </Button>
      </ModalFooter>
    </Modals>
  );
}

export default TermsAndCondition;
