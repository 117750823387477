import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { CardBody, Col, Container, Card, Button } from "reactstrap";
import { APP_NAME, payload, post_url } from "../../../helpers/Constants";
// import { DeleteOutline, Edit, FilterList } from "@material-ui/icons";
// import Swal from "sweetalert2";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { useDispatch, useSelector } from "react-redux";
// import { Toastr } from "../../../helpers/Alert";
import axios from "axios";
// import Error from "../../../helpers/Error";
// import EditZone from "./EditZone";
// import AddZone from "./AddZone";
// import { addZone, deleteZone, editZone } from "../../../store/zoneSlice";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/no_search_results.json";
import notfound from "../../../assets/lotties/not_found_lottie.json";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const Zone = () => {
  const zone = useSelector((state) => state.zoneSlice.zone);
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const selected_zone = useSelector((state) => state.zoneSlice.activeZone);
  const selectedSite = useRef();
  const tableRef = useRef();
  const totalRecordsCount = useRef();
  const urlBackup = useRef();
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Zone List", link: "#" },
    ],
  });
  const [filterEnabled] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchActive, setSearchActive] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (selected_site !== null) {
  //     setLoader(true);
  //     const data = {
  //       url: `/abaci_count/zone`,
  //       url_params: { site: selected_site.id },
  //       ...payload,
  //     };

  //     axios
  //       .post(post_url, data)
  //       .then((response) => {
  //         dispatch(addZone(response.data));
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         const err_msg = Error(error);
  //         Toastr("error", err_msg);
  //       });
  //   }
  //   return () => {
  //     dispatch(addZone([]));
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selected_site]);

  useEffect(() => {
    if (selected_site !== null) {
      selectedSite.current = selected_site.id;
      tableRef.current.onQueryChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_site]);

  const getData = (query) => {
    
  return new Promise((resolve, reject) => {

    if (selected_site !== null) {
      let orderBy = "";
      let search = "";
      let url = "";
      let ordering = "";

      if (query.orderBy) {
        orderBy =
          query.orderDirection === "asc"
            ? (ordering = `-${query.orderBy?.field}`)
            : (ordering = query.orderBy?.field);
      }
      if (query.search) {
        setLoader(true);
        setSearchActive(true);
        let data = query.search.split("-");

        if (data.length === 3) {
          let date = moment(query.search).format(
            "YYYY-DD-MM"
          );
          // console.log(date);
          if (date !== "Invalid date") {
            // console.log("first");
            search = date;
          }
        } else {
          search = query.search;
        }
      } else {
        setSearchActive(false);
      }

      url = `/abaci_count/zones`   

      const data = {
              url: url,
              ...payload,
              url_params: {
                offset: query.pageSize * query.page,
                search: query.search,
                limit: query.pageSize,
                orderBy: orderBy,
                ordering: ordering,
                site: selectedSite.current
              }
            };

            urlBackup.current = data
      axios
        .post(url,data)
        .then((response) => {

          totalRecordsCount.current = response.data?.count;

          const TempData = response.data.results.map(
            (data, index) => ({
              ...data,
              // sl_no: index + 1,
              site_name: data?.site?.site_name
                ? data.site.site_name
                : "-",
              rental_space: data?.rental_space
                ? data.rental_space
                : "-",
              allowed_capacity: data?.total_capacity
                ? data.total_capacity
                : "-",
              threshold: data?.threshold
                ? data.threshold
                : "-",

              start_time_formated: data?.start_time
                ? moment(
                    data.start_time,
                    "HH:mm:ss"
                  ).format("h:mmA")
                : "-",
              end_time_formated: data?.end_time
                ? moment(data.end_time, "HH:mm:ss").format(
                    "h:mmA"
                  )
                : "-",
              time_interval: data?.time_interval
                ? data.time_interval
                : "-",
              zone_name: data.zone_name
                ? data.zone_name
                : "-",
            })
          );
          resolve({
            data: TempData,
            page:
              response.data?.results.length === 0
                ? 0
                : query.page,
            totalCount: response.data?.count,
          });
        })
        .catch((error) => {
          reject({
            data: [],
            page: query.page,
            totalCount: 0,
          });
        });
    } else {
      
      reject({
        data: [],
        page: query.page,
        totalCount: 0,
      });
     
    }
  }).finally(() => {
    setLoader(false); // Indicate that the query is complete
  });
  };
  

  // useEffect(() => {
  //   if (zone !== null) {
  //     const temp = zone.map((data, index) => ({
  //       ...data,
  //       sl_no: index + 1,
  //       site_name: data?.site?.site_name ? data.site.site_name : "-",
  //       rental_space: data?.rental_space ? data.rental_space : "-",
  //       allowed_capacity: data?.total_capacity ? data.total_capacity : "-",
  //       threshold: data?.threshold ? data.threshold : "-",

  //       start_time_formated: data?.start_time
  //         ? moment(data.start_time, "HH:mm:ss").format("h:mmA")
  //         : "-",
  //       end_time_formated: data?.end_time
  //         ? moment(data.end_time, "HH:mm:ss").format("h:mmA")
  //         : "-",
  //       time_interval: data?.time_interval ? data.time_interval : "-",
  //       zone_name: data.zone_name ? data.zone_name : "-",
  //     }));

  //     setSiteData(temp);
  //   }
  // }, [zone]);

  // const addSiteModalToggle = () => {
  //   setModalShow(!modalShow);
  // };

  // const UpdateHandler = (id, method) => {
  //   if (method === "Disabled") {
  //     const url = `/abaci_count/zones/${id}`;

  //     axios
  //       .patch(url, { status: method })
  //       .then((response) => {
  //         dispatch(editZone(response.data));
  //         Toastr("success", " Zone Updated Succesfully");
  //       })
  //       .catch((error) => {
  //         const err_msg = Error(error);
  //         Toastr("error", err_msg);
  //       });
  //   } else if (method === "Active") {
  //     const url = `/abaci_count/zones/${id}`;

  //     axios
  //       .patch(url, { status: method })
  //       .then((response) => {
  //         //   dispatch(editZone(response.data));
  //         // props.tableRef.current.onQueryChange();
  //         Toastr("success", " Zone Updated Succesfully");
  //       })
  //       .catch((error) => {
  //         const err_msg = Error(error);
  //         Toastr("error", err_msg);
  //       });
  //   } else if (method === "Deleted") {
  //     const url = `/abaci_count/zones/${id}`;

  //     axios
  //       .patch(url, { status: method })
  //       .then((response) => {
  //         dispatch(deleteZone(id));
  //         // props.tableRef.current.onQueryChange();
  //         Toastr("success", " Zone Deleted Succesfully");
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         const err_msg = Error(error);
  //         Toastr("error", err_msg);
  //       });
  //   }
  // };

  const columns = [
    { title: "SL No", field: "sl_no" },
    { title: "Zone Name", field: "zone_name" },
    // { title: "Site", field: "site_name" },
    { title: "Total Area(sqm)", field: "rental_space" },
    { title: " Total Capacity(Person)", field: "total_capacity" },
    { title: "Allowed Capacity(Person)", field: "threshold" },
    { title: "Start Time", field: "start_time_formated" },
    { title: "End Time", field: "end_time_formated" },
  ];

  const options = {
    exportFileName: "Zone",
    exportAllData: true,
    actionsColumnIndex: -1,
    exportButton: true,
    filtering: false,
    debounceInterval: 500,
    search: true,
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Zone List"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>
         
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    style={{ zIndex: "0" }}
                    onSearchChange={handleSearch}
                    title=""
                    columns={columns}
                    data={getData}

                    isLoading={loader}
                    onRowClick={(event, rowData) => {
                      navigate(`/zonedetails/${rowData.id}`);
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <div style={{ height: "300px" }}>
                            {!loader && (
                              <>
                                {" "}
                                <Player
                                  autoplay
                                  loop
                                  src={
                                    searchActive || filterEnabled
                                      ? lottie
                                      : notfound
                                  }
                                  style={{
                                    height: "200px",
                                    width: "170px",
                                  }}
                                />
                                <span style={{ fontSize: "13px" }}>
                                  {searchActive || filterEnabled
                                    ? "Oops! No results found !"
                                    : " Oops! No Zones have been added. Please add your Zones to begin counting"}
                                </span>
                              </>
                            )}
                          </div>
                        ),
                      },
                    }}
                    // actions={[
                    //   {
                    //     icon: FilterList,
                    //     tooltip: filterEnabled
                    //       ? "Disable Filter"
                    //       : "Enable Filter",
                    //     isFreeAction: true,
                    //     onClick: (event) => {
                    //       setFilterEnabled((state) => !state);
                    //     },
                    //   },

                    //   {
                    //     icon: Edit,
                    //     tooltip: "Edit Site",
                    //     onClick: (event, rowData) => {
                    //       // console.log(rowData)
                    //       // setEditClientModalShow(true)
                    //       setItemToBeEdited(rowData);
                    //       editModalToggle();
                    //     },
                    //   },

                    //   (rowData) => ({
                    //     icon: DeleteOutline,
                    //     tooltip: "Delete Site",
                    //     onClick: (event, rowData) => {
                    //       Swal.fire({
                    //         title: "Are you sure?",
                    //         text: "You won't be able to revert this!",
                    //         icon: "warning",
                    //         showCancelButton: true,
                    //         confirmButtonColor: "#3085d6",
                    //         cancelButtonColor: "#d33",
                    //         confirmButtonText: "Yes, delete it!",
                    //       }).then((result) => {
                    //         if (result.isConfirmed) {
                    //           UpdateHandler(rowData.id, "Deleted");
                    //           // deleteClientData(rowData?.id)
                    //         }
                    //       });
                    //       // setDeleteModal({ id: rowData?.id, toggle: true });
                    //     },
                    //   }),
                    // ]}
                    icons={tableIcons}
                    options={options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};
