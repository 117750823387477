import { jsPDF } from "jspdf";
// import autoTable from 'jspdf-autotable'

var base64Img = new Image();
base64Img.src = require("../assets/images/abaci_count.png");

export const pdfGenerator = (headRowData, bodyRowData, name) => {
  require("jspdf-autotable");
  const doc = new jsPDF({
    format: "legal",
    orientation: "landscape",
  });
  const totalPagesExp = "{total_pages_count_string}";
  doc.autoTable({
    head: headRowData,
    body: bodyRowData,
    styles: { font: "helvetica", fontSize: 8 },
    didDrawPage: function (data) {
      // Header
      doc.setTextColor(40);
      if (base64Img) {
        doc.addImage(base64Img, "JPEG", data.settings.margin.left, 12, 40, 10);
      }
      doc.setFontSize(14);
      doc.text(name, data.settings.margin.left + 48, 20);

      // Footer
      var str = "Page " + doc.internal.getNumberOfPages();
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        str = str + " of " + totalPagesExp;
      }
      doc.setFontSize(8);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(str, data.settings.margin.left, pageHeight - 10);
    },
    margin: { top: 30 },
  });

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  return doc;
};
