import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { CardBody, Col, Container, Card, Button } from "reactstrap";
import { APP_NAME, payload, post_url } from "../../../helpers/Constants";
import { DeleteOutline, Edit } from "@material-ui/icons";
import Swal from "sweetalert2";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../helpers/Alert";
import LockIcon from "@material-ui/icons/Lock";
import Unlock from "@material-ui/icons/LockOpen";
import Error from "../../../helpers/Error";
import { addUser, deleteUser, editUser } from "../../../store/usersSlice";
import AddUser from "./AddUsers";
import EditUser from "./EditUser";
import axios from "axios";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/no_search_results.json";
import notfound from "../../../assets/lotties/not_found_lottie.json";
import Cookies from "js-cookie";
export const Users = () => {
  const userDetails = useSelector((state) => state.userSlice.user);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Users", link: "#" },
    ],
  });
  const [userData, setUserData] = useState([]);
  const [filterEnabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchActive, setSearchActive] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookies.get("login_token");

    const url = "/users_api/client_user";
    axios
      .post(post_url, {
        ...payload,
        url: url,
        token: token,
      })

      .then((response) => {
        dispatch(addUser(response.data));
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });

    return () => {
      dispatch(addUser([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetails !== null) {
      const temp = userDetails.map((data, index) => ({
        ...data,
        sl_no: index + 1,
        full_name: data?.full_name ? data.full_name : "-",
        department: data?.department ? data.department : "-",

        contact_number: data?.contact_number ? data.contact_number : "-",
        designation: data?.designation ? data.designation : "-",
        username: data?.username ? data.username : "-",
        email: data?.email ? data.email : "-",
        address: data?.address ? data.address : "-",
      }));

      setUserData(temp);
    }
  }, [userDetails]);

  const addUserModalToggle = () => {
    setModalShow(!modalShow);
  };

  const columns = [
    { title: "Sl No", field: "sl_no" },
    { title: "Name", field: "full_name" },
    { title: "Username", field: "username" },
    { title: "Department", field: "department" },
    { title: "Designation", field: "designation" },
    { title: "Phone", field: "contact_number" },
    { title: "Email", field: "email" },
    { title: "User Type", field: "user_type" },
    { title: "Status", field: "user_status" },
  ];

  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  };

  return (
    <React.Fragment>
      {editModalShow && (
        <EditUser
          title={"Edit User"}
          isOpen={editModalShow}
          toggle={editModalToggle}
          item={itemToBeEdited}
        />
      )}

      {modalShow && (
        <AddUser
          title={"Add User"}
          toggle={addUserModalToggle}
          isOpen={modalShow}
          //   tableRef={tableRef}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Users List"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>
          {/* <div
            className="disappearing-button-container-page-top-right"
            style={{ marginRight: "-60px" }}
          >
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#1976D2",
                borderColor: "#1976D2",
              }}
              type="button"
              className="w-xs waves-effect waves-light me-1"
              onClick={addUserModalToggle}
            >
              Add Users
            </Button>
          </div> */}
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    style={{ zIndex: "0" }}
                    title=""
                    columns={columns}
                    data={userData}
                    onSearchChange={handleSearch}
                    isLoading={loader}
                    // actions={[
                    //   (rowData) => ({
                    //     icon: Unlock,
                    //     tooltip: "Activate User",
                    //     onClick: (event, rowData) => {
                    //       Swal.fire({
                    //         title: "Are you sure?",

                    //         icon: "info",
                    //         showCancelButton: true,
                    //         confirmButtonColor: "#3085d6",
                    //         cancelButtonColor: "#d33",
                    //         confirmButtonText: "Yes, Enable it!",
                    //       }).then((result) => {
                    //         if (result.isConfirmed) {
                    //           UpdateHandler(rowData.id, "Activated");
                    //         }
                    //       });
                    //     },
                    //     hidden:
                    //       rowData.user_status === "Activated" ||
                    //       rowData.user_status === "Invited",
                    //   }),
                    //   (rowData) => ({
                    //     icon: LockIcon,
                    //     tooltip: "Deactivate User",
                    //     onClick: (event, rowData) => {
                    //       Swal.fire({
                    //         title: "Are you sure?",
                    //         icon: "info",
                    //         showCancelButton: true,
                    //         confirmButtonColor: "#3085d6",
                    //         cancelButtonColor: "#d33",
                    //         confirmButtonText: "Yes, Desable it!",
                    //       }).then((result) => {
                    //         if (result.isConfirmed) {
                    //           UpdateHandler(rowData.id, "Deactivated");
                    //         }
                    //       });
                    //     },
                    //     hidden:
                    //       rowData.user_status === "Deactivated" ||
                    //       rowData.user_status === "Invited",
                    //   }),

                    //   {
                    //     icon: Edit,
                    //     tooltip: "Edit User",
                    //     onClick: (event, rowData) => {
                    //       setItemToBeEdited(rowData);
                    //       editModalToggle();
                    //     },
                    //   },

                    //   (rowData) => ({
                    //     icon: DeleteOutline,
                    //     tooltip: "Delete User",
                    //     onClick: (event, rowData) => {
                    //       Swal.fire({
                    //         title: "Are you sure?",
                    //         text: "You won't be able to revert this!",
                    //         icon: "warning",
                    //         showCancelButton: true,
                    //         confirmButtonColor: "#3085d6",
                    //         cancelButtonColor: "#d33",
                    //         confirmButtonText: "Yes, Delete it!",
                    //       }).then((result) => {
                    //         if (result.isConfirmed) {
                    //           UpdateHandler(rowData.id, "Deleted");
                    //         }
                    //       });
                    //     },
                    //     hidden: rowData.user_status === "Activated",
                    //   }),
                    // ]}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <div style={{ height: "300px" }}>
                            {!loader && (
                              <>
                                {" "}
                                <Player
                                  autoplay
                                  loop
                                  src={
                                    searchActive || filterEnabled
                                      ? lottie
                                      : notfound
                                  }
                                  style={{
                                    height: "200px",
                                    width: "170px",
                                  }}
                                />
                                <span style={{ fontSize: "13px" }}>
                                  {searchActive || filterEnabled
                                    ? "Oops! No results found !"
                                    : " Oops! No Users have been added. Please add your Users "}
                                </span>
                              </>
                            )}
                          </div>
                        ),
                      },
                    }}
                    options={{
                      exportFileName: "User",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      filtering: filterEnabled,
                      debounceInterval: 500,
                    }}
                    icons={tableIcons}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};
