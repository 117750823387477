// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  site: [],
  selected_site: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    addSite(state, action) {
      state.site = action.payload;
    },
    selectedSite(state, action) {
      state.selected_site = action.payload;
    },
    addNewSite(state, action) {
      state.site = [...state.site, action.payload];
    },
    editSite(state, action) {
      state.site = state.site.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    deleteSite(state, action) {
      state.site = state.site.filter((data) => data.id !== action.payload);
    },
  },
});

export const { selectedSite, addSite, addNewSite, editSite, deleteSite } =
  siteSlice.actions;
export default siteSlice.reducer;
