import React from "react";
import Login from "../containers/Pages/Authentication/Login";
import Dashboard from "../containers/Pages/Dashboard/index";
import Settings from "../containers/Pages/Settings";
import Register from "../containers/Pages/Registration/Register";
import ForgetPassword from "../containers/Pages/Authentication/ForgetPassword";
import { Users } from "../containers/Pages/Users/Users";
import LoginDetails from "../containers/Pages/Reports/LoginReport";
import NotificationsPage from "../containers/Pages/Notifications/NotificationsPage";
import { Zone } from "../containers/Pages/Zone/Zone";
import { Sensor } from "../containers/Pages/Sensor/Sensor";
import ZoneDetailsPage from "../containers/Pages/Zone/ZoneDetailsPage";
import { SensorDetails } from "../containers/Pages/Sensor/SensorDetails/SensorDetails";
import { SensorReport } from "../containers/Pages/Reports/SensorReport";
import { SiteReport } from "../containers/Pages/Reports/SiteReport";
import { ZoneReport } from "../containers/Pages/Reports/ZoneReport";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    sidebar_presence: true,
    icon_class: "ri-dashboard-line",
    name: "Dashboard",
    layout_wrapper_required: true,
    appType: ["Admin", "Viewer", "User"],
  },
  {
    name: "Sensor",
    path: "/sensor",
    component: <Sensor />,
    icon_class: " ri-camera-3-fill",
    sidebar_presence: true,
    appType: ["Admin", "Viewer", "User"],
    icon_class: " ri-camera-3-fill",
    layout_wrapper_required: true,
  },
  // {
  //   path: "/#",
  //   component: "",
  //   sidebar_presence: true,
  //   icon_class: " ri-camera-3-fill",
  //   icon_class2: "ri-arrow-down-s-fill",
  //   appType: ["Admin", "Viewer", "User"],
  //   name: "Sensor",
  //   layout_wrapper_required: true,
  //   submenu: true,
  //   submenu_components: [
  //     {
  //       name: "Sensor Details",
  //       path: "/sensor",
  //       icon_class: "ri-camera-fill",
  //     },
  //     {
  //       name: "Live Stream",
  //       path: "/livestream",
  //       icon_class: "ri-vidicon-line",
  //     },
  //   ],
  // },

  {
    path: "/zonedetails/:id",
    component: <ZoneDetailsPage />,
    sidebar_presence: false,
    icon_class: " ri-community-line",
    name: "Zone Details",
    layout_wrapper_required: true,
    appType: ["Admin", "Viewer", "User"],
  },

  {
    path: "/zone",
    component: <Zone />,
    sidebar_presence: true,
    icon_class: "ri-layout-masonry-fill",
    name: "Zone",
    layout_wrapper_required: true,
    appType: ["Admin", "Viewer", "User"],
  },
  {
    path: "/#",
    component: "",
    sidebar_presence: true,
    icon_class: " ri-file-copy-line",
    icon_class2: "ri-arrow-down-s-fill",
    appType: ["Admin", "Viewer", "User"],

    name: "Reports",
    layout_wrapper_required: true,
    submenu: true,
    submenu_components: [
      {
        name: "Site Reports",
        path: "/sitereport",
        icon_class: "ri-file-text-line",
      },
      {
        name: "Sensor Reports",
        path: "/sensorreport",
        icon_class: "ri-camera-3-fill",
      },

      {
        name: "Zone Reports",
        path: "/zonereport",
        icon_class: "ri-layout-masonry-fill",
      },
      // {
      //   name: "Login Reports",
      //   path: "/logindetails",
      //   icon_class: "ri-file-lock-line",
      // },
    ],
  },
  {
    path: "/users",
    component: <Users />,
    sidebar_presence: true,
    icon_class: " ri-team-fill",
    name: "Users",
    layout_wrapper_required: true,
    appType: ["Admin", "Viewer", "User"],
  },

  {
    path: "/sensordetails/:id",
    component: <SensorDetails />,
    appType: ["Admin", "Viewer", "User"],

    sidebar_presence: false,
    icon_class: " ri-camera-3-fill",
    name: "Camera",
    layout_wrapper_required: true,
  },

  // {
  //   path: "/logindetails",
  //   component: <LoginDetails />,
  //   icon_class: " ri-team-fill",
  //   appType: ["Admin", "Viewer", "User"],

  //   layout_wrapper_required: true,
  // },
  {
    path: "/notification",
    component: <NotificationsPage />,
    sidebar_presence: false,
    icon_class: " ri-community-line",
    name: "Notifications",
    layout_wrapper_required: true,
    appType: ["Admin", "Viewer", "User"],
  },
  {
    path: "/sensorreport",
    component: <SensorReport />,
    icon_class: "",
    appType: ["Admin", "Viewer", "User"],

    layout_wrapper_required: true,
  },
  // {
  //   path: "/settings",
  //   component: <Settings />,
  //   sidebar_presence: true,
  //   icon_class: "fa fa-cog",
  //   name: "Settings",
  //   layout_wrapper_required: true,
  //   appType: ["Admin", "Viewer", "User"],
  // },
  // {
  //   path: "/livestream",
  //   component: <CameraView />,
  //   appType: ["Admin", "Viewer", "User"],

  //   icon_class: "ri-vidicon-line",
  //   name: "Streaming",
  //   layout_wrapper_required: false,
  // },

  {
    path: "/sitereport",
    component: <SiteReport />,
    appType: ["Admin", "Viewer", "User"],

    icon_class: "ri-vidicon-line",
    name: "Site reports",
    layout_wrapper_required: true,
  },
  {
    path: "/zonereport",
    component: <ZoneReport />,
    appType: ["Admin", "Viewer", "User"],

    icon_class: "ri-vidicon-line",
    name: "Zone reports",
    layout_wrapper_required: true,
  },
];

const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
  { path: "/register/:string", component: <Register /> },
  { path: "/forgotpassword", component: <ForgetPassword /> },
];

export { authProtectedRoutes, publicRoutes };
