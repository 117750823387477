// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zone: null,
  activeZone : null
};

const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    addZone(state, action) {
      state.zone = action.payload;
    },
    addNewZone(state, action) {
      state.zone = [...state.zone, action.payload];
    },
    editZone(state, action) {
      state.zone = state.zone.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    deleteZone(state, action) {
      state.zone = state.zone.filter((data) => data.id !== action.payload);
    },
    setActiveZone(state,action) {
      state.activeZone = action.payload
    }
  },
});

export const { addZone, addNewZone, editZone, deleteZone , setActiveZone} = zoneSlice.actions;
export default zoneSlice.reducer;
