// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification_count: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification(state, action) {
      state.notification_count = action.payload;
    },
    readNotification(state, action) {
      state.notification_count = state.notification_count - 1;
    },
    addnewNotificationCount(state, action) {
      state.notification_count = state.notification_count + 1;
    },
  },
});

export const { readNotification, addNotification, addnewNotificationCount } =
  notificationSlice.actions;
export default notificationSlice.reducer;
