import React, { useState } from "react";
import ViewCompactAltIcon from "@mui/icons-material/ViewCompactAlt";
// import { connect } from "react-redux";

import {
  // Row,
  // Col,
  Form,
  FormGroup,
  InputGroup,
  // InputGroupAddon,
  Input,
  Button,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
} from "reactstrap";

import { Link, Navigate, useNavigate } from "react-router-dom";

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logosmlight from "../../assets/images/new_logo.png";
import logolight from "../../assets/images/new_logo.png";
import logodark from "../../assets/images/abaci_count.png";
import { height } from "@mui/system";
// //Import Social Profile Images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";
import logoMini from "../../../src/assets/images/logoMini.png";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SiteDropdown from "../../containers/Pages/SitePage/SiteDropdown";
export default function Header(props) {
  const [isSearch, setIsSearch] = useState(false);
  // const [isSocialPf, setIsSocialPf] = useState(false)
  const navigate = useNavigate();

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const backNavigator = () => {
    navigate("/dashboard");
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {props.logoHide ? (
              <div style={{ marginLeft: "10px", paddingTop: "10px" }}>
                <IconButton onClick={backNavigator}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
            ) : (
              <>
                <div
                  className="navbar-brand-box"
                  style={{ background: "#252b3b" }}
                >
                  <Link to="#" className="logo logo-dark">
                    <span className="logo-sm">
                      <img
                        src={logoMini}
                        alt=""
                        style={{
                          height: "8px",
                          width: "60px",
                          marginLeft: "-5px",
                        }}
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        src={logodark}
                        alt=""
                        style={{
                          height: "35px",
                          width: "150px",
                          marginLeft: "40px",
                        }}
                      />
                    </span>
                  </Link>

                  <Link to="#" className="logo logo-light">
                    <span className="logo-sm">
                      <img
                        src={logoMini}
                        alt=""
                        style={{
                          height: "8px",
                          width: "60px",
                          marginLeft: "-5px",
                        }}
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        src={logodark}
                        alt=""
                        style={{
                          height: "38px",
                          width: "145px",
                          marginLeft: "40px",
                        }}
                      />
                    </span>
                  </Link>
                </div>

                <Button
                  size="sm"
                  color="none"
                  type="button"
                  onClick={props.toggleMenu}
                  className="px-3 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </Button>
              </>
            )}
            <div>
              <SiteDropdown />
            </div>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              {/* <button type="button" onClick={() => { setIsSearch(state => !state); }} className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                <i className="ri-search-line"></i>
                            </button> */}
              <div
                className={
                  isSearch === true
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <Form className="p-3">
                  <FormGroup className="m-0">
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      {/* <InputGroupAddon addonType="append"> */}
                      <Button color="primary" type="submit">
                        <i className="ri-search-line"></i>
                      </Button>
                      {/* </InputGroupAddon> */}
                    </InputGroup>
                  </FormGroup>
                </Form>
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Button
                color="none"
                type="button"
                className="header-item noti-icon waves-effect"
                onClick={toggleFullscreen}
              >
                <i className="ri-fullscreen-line"></i>
              </Button>
            </div>

            {/* <NotificationDropdown /> */}

            <ProfileMenu />

            {/* <div className="dropdown d-inline-block">
                            <Button color="none" onClick={props.toggleRightbar} type="button" className="header-item noti-icon right-bar-toggle waves-effect">
                                <i className="ri-settings-2-line"></i>
                            </Button>
                        </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
