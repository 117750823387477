import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { addSite, selectedSite } from "../../../store/siteSlice";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import AddSite from "./AddSite";
import EditSite from "./EditSite";
import DropDownItem from "./DropDownItem";
import { useLocation } from "react-router-dom";
import { payload, post_url } from "../../../helpers/Constants";
import Cookies from "js-cookie";
export default function SiteDropdown(props) {
  const site = useSelector((state) => state.siteSlice.site);
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const [menu, setMenu] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [disableSite, setDisableSite] = useState(true);

  useEffect(() => {
    if (
      location.pathname.includes("/sensordetails") ||
      location.pathname.includes("/zonedetails/")
    ) {
      setDisableSite(false);
    } else {
      setDisableSite(true);
    }
  }, [location]);

  useEffect(() => {
    const StoredSite = localStorage.getItem("selected-site");
    const token = Cookies.get("login_token");
    if (token !== "undefined") {
      const url = `/abaci_count/site`;
      axios
        .post(post_url, {
          ...payload,
          url: url,
          url_params: { limited: true },
          token: token,
        })
        .then((response) => {
          // console.log(response.data);
          dispatch(addSite(response.data));
          if (StoredSite) {
            const temp = response.data.find(
              (data) => data.id === Number(StoredSite)
            );
            dispatch(selectedSite(temp));
          } else {
            dispatch(selectedSite(response.data[0]));
          }
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectSite = (data) => {
    dispatch(selectedSite(data));
    localStorage.setItem("selected-site", data.id);
  };

  const addSiteModalToggle = () => {
    setModalShow(!modalShow);
  };
  const editModalToggle = (data) => {
    setEditModalShow(!editModalShow);
    setEditItem(data);
  };

  return (
    <React.Fragment>
      {editModalShow && (
        <EditSite
          title={"Edit Site"}
          isOpen={editModalShow}
          toggle={editModalToggle}
          item={editItem}
        />
      )}
      {modalShow && (
        <AddSite
          title={"Add Site"}
          toggle={addSiteModalToggle}
          isOpen={modalShow}
          //   tableRef={tableRef}
        />
      )}
      <Dropdown
        isOpen={menu}
        toggle={() => disableSite && setMenu((state) => !state)}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2px",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                paddingLeft: "3px",
                width: "150px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              className="text-start"
            >
              {selected_site && selected_site.site_name}
            </span>
            <span
              className="d-none d-xl-inline-block ms-1 text-transform text-start"
              style={{ fontWeight: "bold" }}
            >
              Sites
              <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-start"
          style={{ width: "250px", maxHeight: "300px" }}
        >
          <div className="site-dropdown-container">
            {site.length === 0 ? (
              <DropdownItem>Sites not found!</DropdownItem>
            ) : (
              site.map((data, index) => (
                <DropDownItem
                  key={data.id}
                  data={data}
                  handleSelectSite={handleSelectSite}
                  editModalToggle={editModalToggle}
                />
              ))
            )}
          </div>
          <div
            className="d-flex justify-content-center"
            style={{
              padding: "10px",
              paddingLeft: "20px",
              color: "#1976D2",
              borderTop: "1px solid #EFF2F7",
              cursor: "pointer",
              height: "30px",
            }}
            // onClick={() => addSiteModalToggle()}
          ></div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}
