import React from 'react';
import {  Container } from 'reactstrap';



function Index() {

//   const [finishStatus, _setfinishStatus] = useState(false);

// const finishStatusRef = React.useRef(finishStatus);
// const setfinishStatus = data => {
//   finishStatusRef.current = data;
//   _setfinishStatus(data);
// };

// const onBackButtonEvent = (e) => {
//     e.preventDefault();
//     if (!finishStatusRef.current) {
//         if (window.confirm("Do you want to go back ?")) {
//             setfinishStatus(true)
//             // your logic
//             window.history.push("/");
//         } else {
//             window.history.pushState(null, null, window.location.pathname);
//             setfinishStatus(false)
//         }
//     }
// }

// const _backConfirm = async () => {
//   let event = window.confirm("Changes that you may not be saved.");
//   if(event){
//       window.history.pushState(null, "", window.location.href);
//   }
// }







//   const [state,setState]=useState(
//     {
//       latitude: null,
//       longitude: null,
//     }
//   )
//  const position = async () => {
//     await navigator.geolocation.getCurrentPosition(
//       position => console.log(position,'position'),
//       err => console.log(err)
//     );
  
//   }

// import React, { useState } from "react";

// export default function MyComponent() {
//   const [data, setData] = useState("");
//   const [error, setError] = useState("");

//   // Custom validation function
//   const validateInput = (value) => {
//     if (!value) {
//       return "This field is required";
//     }
    
//     // Add your custom validation logic here
//     const isValid = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(value);
//     if (!isValid) {
//       return "Invalid MAC Address";
//     }
    
//     return ""; // No error
//   };

//   const handleInputChange = (e) => {
//     const newValue = e.target.value;
//     const validationError = validateInput(newValue);
    
//     setData(newValue);
//     setError(validationError);
//   };

//   const handleClick = () => {
//     const validationError = validateInput(data);
//     setError(validationError);
//   };

//   return (
//     <div>
//       <input
//         onClick={handleClick}
//         placeholder="MAC Address"
//         type="text"
//         className="form-control"
//         value={data}
//         onChange={handleInputChange}
//       />
//       {error && <span className="error-message">{error}</span>}
//     </div>
//   );
// }

  return (
    <React.Fragment>
   
    
  
            <div className="page-content">
              <Container fluid>
              <button >Filter</button>
                    </Container>
                    </div>
                    </React.Fragment>
  )
}

export default Index;

