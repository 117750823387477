import React from "react";
import "./Loader.css";
export const Loader = () => {
  return (
    <div className="ldio-o9d20oe2cwi">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
