import React, { useState, useEffect } from "react";
import { Spinner, Button, ModalFooter } from "reactstrap";
import axios from "axios";
// import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Toastr } from "../../../helpers/Alert";
import Modals from "../../Modal";
// import { useSelector } from "react-redux";
export default function ChangePassword(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [allClear, setallClear] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);
  // const profile = useSelector((state) => state.authSlice.profile);
  useEffect(() => {
    const re = new RegExp(
      `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`
    );
    const isOk = re.test(newPassword);

    if (isOk) {
      seterrorMessage("Passwords should match");
      if (newPassword === confirmNewPassword) {
        seterrorMessage(null);
        if (oldPassword.length > 3) {
          setallClear(true);
        }
      } else {
        setallClear(false);
      }
    } else {
      seterrorMessage(
        "The password should contain minimum 8 characters and maximum of 20 with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
      );
    }
  }, [newPassword, confirmNewPassword, oldPassword]);
  //   useEffect(() => {
  //     if (props.isOpen) {
  //       setNewPassword("");
  //       setConfirmNewPassword("");
  //       setOldPassword("");
  //     }
  //   }, [props.isOpen]);

  const handleCancel = () => {
    props.toggle();
    setNewPassword("");
    setConfirmNewPassword("");
    setOldPassword("");
    setallClear(false);
  };

  const passwordFormSubmitHandler = () => {
    setwaitingForAxios(true);
    axios
      .post(`/users_api/change_password`, {
        old_password: oldPassword,
        new_password: newPassword,
      })
      .then(() => {
        setwaitingForAxios(false);
        // handleCancel();
        props.toggle();
        Toastr("success", "Password has been updated Successfully !");
      })
      .catch((error) => {
        setwaitingForAxios(false);

        try {
          if (error.response.status === 401) {
            seterrorMessage(
              "Provided old password is wrong. Please try again !"
            );
          } else {
            handleCancel();

            Toastr(
              "error",
              "Error occured while updating the password, please retry !"
            );
          }
        } catch {
          handleCancel();

          Toastr(
            "error",
            "Error occured while updating the password, please retry !"
          );
        }
      });
  };
  return (
    <div>
      <Modals
        isOpen={props.isOpen}
        toggle={props.toggle}
        title={props.title}
        size={props.size}
      >
        <div style={{ margin: "30px" }}>
          <form className="addform" type="submit">
            <input
              type="password"
              placeholder="Current Password *"
              name="Currentpassword"
              className="form-control form-control-sm"
              style={{ marginTop: "1rem" }}
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
              disabled={waitingForAxios}
            />

            <input
              type="password"
              placeholder="New Password *"
              name="newpassword"
              className="form-control form-control-sm"
              onChange={(event) => setNewPassword(event.target.value)}
              disabled={waitingForAxios}
              value={newPassword}
              maxLength="20"
              style={{ marginTop: "1rem" }}
            />

            <input
              type="password"
              placeholder="Confirm Password *"
              name="confirmpassword"
              className="form-control form-control-sm"
              onChange={(event) => setConfirmNewPassword(event.target.value)}
              disabled={waitingForAxios}
              value={confirmNewPassword}
              style={{ marginTop: "1rem" }}
              maxLength="20"
            />
            <span style={{ color: "red" }}>
              {errorMessage ? errorMessage : null}
            </span>
          </form>
        </div>

        <ModalFooter>
          <Button
            type="button"
            style={{
              backgroundColor: "#1976D2",
              borderColor: "#1976D2",
            }}
            className="w-xs waves-effect waves-light me-1"
            disabled={!allClear || waitingForAxios}
            onClick={passwordFormSubmitHandler}
          >
            {waitingForAxios ? <Spinner animation="grow" size="sm" /> : "Save"}
          </Button>
          <Button
            type="submit"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modals>
    </div>
  );
}
