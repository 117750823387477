import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import axios from "axios";
import Error from "../../../helpers/Error";
import { Color, payload, post_url } from "../../../helpers/Constants";
import { useSelector } from "react-redux";
import lottie from "../../../assets/lotties/not_found_lottie.json";
import { Player } from "@lottiefiles/react-lottie-player";
import SyncIcon from "@mui/icons-material/Sync";
import Tooltip from "@mui/material/Tooltip";
export default function BarGraph(props) {
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState(null);
  const [state, setState] = useState({
    series: [],
    options: {},
  });
  const [loader, setLoader] = useState(true);
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const selected_zone = useSelector((state) => state.zoneSlice.activeZone);
  //graph
  const graphHandler = () => {
    setState({ series: [], options: {} });
    setLoader(true);
    let url = "";

    let data = {
      ...payload,
    };
    if (props.type === "Dashboard") {
      data = {
        ...data,
        url: `/abaci_count/visitor_distribution`,
        url_params: { site: selected_site?.id },
      };
    } else {
      data = {
        ...data,
        url: "/abaci_count/visitor_distribution",
        url_params: { site: selected_site?.id, zone: Number(selected_zone) },
      };
    }
    axios
      .post(post_url, data)
      .then((res) => {
        setCategories(res.data.categories.length);
        if (res.data.series.length !== 0) {
          setState({
            series: res.data.series,
            options: {
              chart: {
                type: "bar",
                zoom: { enabled: !1 },
                toolbar: {
                  show: true,

                  export: {
                    svg: {
                      filename: "Total Count",
                    },
                    png: {
                      filename: "Total Count",
                    },
                    csv: {
                      filename: "Total Count",
                    },
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: true,
                },
              },

              colors: Color,
              dataLabels: { enabled: false },
              stroke: {
                width: [3, 3, 3, 3, 3, 3, 3, 3],
                curve: "straight",
              },
              title: {
                align: "left",
                style: { fontWeight: "normal", fill: "#373d3f", opacity: 1 },
              },
              grid: {
                row: { colors: ["transparent", "transparent"], opacity: 0.2 },
                borderColor: "#f1f1f1",
              },
              markers: { style: "inverted", size: 6 },
              xaxis: {
                categories: res.data.categories,
                title: { text: "" },
              },
              yaxis: {
                title: { text: "" },
              },
              legend: {
                position: "bottom",
                horizontalAlign: "center",
                floating: !2,
                offsetY: 8,
                offsetX: -5,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: { toolbar: { show: true } },
                    legend: { show: !1 },
                  },
                },
              ],
            },
          });

          setError(null);
        } else {
          setError("No data to display !");
        }

        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setState({ series: [], options: {} });
        setError(Error(error));
      });
  };

  useEffect(() => {
    if (selected_site || (selected_zone && selected_site)) {
      graphHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_zone, selected_site]);

  return (
    <React.Fragment>
      <Container fluid>
        <Card
          style={{
            minHeight: "650px",
            marginLeft: "-6px",
            marginRight: "-6px",
            paddingRight: "15px",
          }}
        >
          <CardBody>
            <div className="graph-heading-container ">
              <h4 className="card-title mb-4">{props.title}</h4>
              {!loader && (
                <SyncIcon
                  style={{
                    position: "absolute",
                    right: "17px",
                    top: "84px",
                    color: "#677486",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => graphHandler()}
                />
              )}
            </div>

            <div id="donut-chart" className="apex-charts bar-graph-charts ">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="bar"
                height={categories > 20 ? 500 + categories * 10 : 500}
              />
              {error ? (
                <>
                  <p style={{ marginBottom: "-10px" }} className="text-center">
                    {error}
                  </p>
                </>
              ) : null}
            </div>

            {loader ? (
              <div className="position_center_class">
                <GraphLoader />
              </div>
            ) : null}

            {selected_site === null && (
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%,-40%)",
                }}
              >
                <>
                  <Player
                    autoplay
                    loop
                    src={lottie}
                    style={{
                      height: "150px",
                      width: "200px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>
                    Oops! No Site have been selected.
                  </span>
                  <p style={{ fontSize: "13px" }} className="text-center">
                    Please select a site.
                  </p>
                </>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
}
