import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Route, Routes as Switch } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import VerticalLayout from "./components/VerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import Test from "./helpers/Test";
import Error from "./containers/Pages/Error";
import PrivateRoute from "./PrivateRoute";

export default function Routes() {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path="/testforraw" element={<Test />} />
        {publicRoutes.map((route, idx) => (
          <Route
            exact
            path={route.path}
            layout={NonAuthLayout}
            element={route.component}
            key={idx}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            exact
            path={route.path}
            layout={VerticalLayout}
            element={
              <PrivateRoute appType={route.appType}>
                <Layout
                  component={route.component}
                  layout_wrapper_required={route.layout_wrapper_required}
                />
              </PrivateRoute>
            }
            key={idx}
          />
        ))}
        <Route path="*" element={<Error />} />
      </Switch>
    </ErrorBoundary>
  );
}

const Layout = ({ component, layout_wrapper_required }) => {
  if (layout_wrapper_required) {
    return <VerticalLayout>{component}</VerticalLayout>;
  } else {
    return component;
  }
};
