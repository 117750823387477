import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import defaultImage from "../../../assets/images/default.png";
import { setLogOut } from "../../../store/auth";
import ViewProfile from "./ViewProfile";
import { baseURL } from "../../../helpers/baseURL";
import ChangePassword from "./ChangePassword";
import EditSite from "../../../containers/Pages/SitePage/EditSite";

export default function ProfileMenu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [viewProfile, setViewProfile] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const profile = useSelector((state) => state.authSlice.profile);
  const [menu, setMenu] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const ViewProfileModalToggle = () => {
    setViewProfile(!viewProfile);
  };
  const ChangePasswordToggle = () => {
    setChangePasswordModal(!changePasswordModal);
  };
  const editModalToggle = (data) => {
    setEditModalShow(!editModalShow);
    // setEditItem(data);
  };

  const logoutHandler = () => {
    dispatch(setLogOut());
    Cookies.remove("login_token");
    localStorage.removeItem("login_token");
    navigate("/login");
  };

  useEffect(() => {
    if (profile !== null) {
      if (profile.avatar !== null) {
        setProfileImage(baseURL + profile.avatar);
      } else {
        setProfileImage(defaultImage);
      }

      setName(
        profile.first_name && profile.last_name
          ? profile.first_name + " " + profile.last_name
          : profile.first_name
          ? profile.first_name
          : profile.username
      );
    }
  }, [profile]);

  return (
    <React.Fragment>
      {viewProfile && (
        <ViewProfile
          isOpen={viewProfile}
          toggle={ViewProfileModalToggle}
          title="Profile"
          size="lg"
        />
      )}
      {changePasswordModal && (
        <ChangePassword
          isOpen={changePasswordModal}
          toggle={ChangePasswordToggle}
          title="Change Password"
          size="md"
        />
      )}

      {editModalShow && (
        <EditSite
          title={"Site Details"}
          isOpen={editModalShow}
          toggle={editModalToggle}
          // item={editItem}
        />
      )}
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu((state) => !state)}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user me-1"
            src={profileImage}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 text-transform">
            {name}
          </span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={ViewProfileModalToggle}>
            <i className="ri-user-line align-middle me-1"></i> {"Profile"}
          </DropdownItem>
          <DropdownItem onClick={ChangePasswordToggle}>
            <i className="ri-lock-password-line align-middle me-1"></i>{" "}
            {"Change Password"}
          </DropdownItem>
          {/* <DropdownItem href='#'>
            <i className='ri-wallet-2-line align-middle me-1'></i> {"My Wallet"}
          </DropdownItem>
          <DropdownItem className='d-block' href='#'>
            <span className='badge badge-success float-end mt-1'>11</span>
            <i className='ri-settings-2-line align-middle me-1'></i>{" "}
            {"Settings"}
          </DropdownItem> */}
          <DropdownItem onClick={editModalToggle}>
            <i className="ri-building-line"></i> {"Update Site "}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={logoutHandler}>
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {"Logout"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}
