import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import EditOutlined from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { Toastr } from "../helpers/Alert";
import Error from "../helpers/Error";
import { APP_NAME } from "../helpers/Constants";

export default function CustomBreadcrumbs(props) {
  const [itemsLength, setItemsLength] = useState(props.breadcrumbItems.length);
  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const [value, setValue] = useState(props.title);
  const editToggle = (data) => {
    setEditEnable(true);
  };

  const editNameToggle = () => {
    if (value !== "") {
      const url = `/abaci_count/zones/${props.zone}`;
      axios
        .patch(url, { zone_name: value, is_synced_to_cloud: false })
        .then((response) => {
          props.setZoneData(response.data);
          setEditEnable(false);
        })
        .catch((error) => {
          setValue(props.title);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else {
      setEditEnable(false);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div
              className="d-flex"
              onMouseEnter={() => setMouseOnTitle(true)}
              onMouseLeave={() => setMouseOnTitle(false)}
              onClick={() => editToggle()}
              // style={{ width: "100%" }}
            >
              {editEnable ? (
                <>
                  <input
                    type="text"
                    autoFocus
                    style={{
                      borderBottom: "1px solid black",
                      width: "100%",
                      border: "none",
                      backgroundColor: "#F1F5F7",
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={props.title}
                    onMouseLeave={() => editNameToggle()}
                  ></input>
                </>
              ) : (
                <>
                  <h4
                    className="mb-0"
                    style={{ userSelect: "none", cursor: "pointer" }}
                    onClick={() => editToggle()}
                  >
                    {props.title}
                  </h4>
                  {mouseOnTitle ? (
                    <EditOutlined
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        marginTop: "2px",
                        marginLeft: "5px",
                      }}
                      color={mouseOnEdit ? "action" : "disabled"}
                      onClick={() => editToggle()}
                    />
                  ) : null}
                </>
              )}
            </div>

            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                {props.breadcrumbItems.map((item, key) =>
                  key + 1 === itemsLength ? (
                    <BreadcrumbItem key={key} active>
                      {item.title}
                    </BreadcrumbItem>
                  ) : (
                    <BreadcrumbItem key={key}>
                      <Link to={item.link}>{item.title}</Link>
                    </BreadcrumbItem>
                  )
                )}
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
