import React from "react";
import "./GraphLoaderSmall.css";
export default function GraphLoaderSmall() {
  return (
    <div className="container-small-loader">
      <div className="loader-small">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
