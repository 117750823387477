import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isNotEqual } from "../../../helpers/functions";

function EditSensor(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.item !== null) {
      const tempdata = {
        ...props.item,
        username: props.item.username === "-" ? "" : props.item.username,
        port: props.item.port === "-" ? "" : props.item.port,
      };
      // console.log(props.item);
      reset(tempdata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = (data) => {
    if (isNotEqual(data, props.item)) {
      let formData = new FormData();
      if (props?.item?.manufacturer?.manufacturer_name === "Axis") {
        formData.append("port", data.port);
        formData.append("username", data.username);
        formData.append("password", data.password);
        formData.append("serial_number", data.serial_number);
      }

      formData.append("sensor_name", data.sensor_name);
      formData.append("ip_address", data.ip_address);
      formData.append("mac_add", data.mac_add);
      formData.append("is_synced_to_cloud", false);

      setwaitingForAxios(true);
      const url = `/abaci_count/sensors/${props.item.id}`;
      axios
        .patch(url, formData)
        .then((response) => {
          setwaitingForAxios(false);
          props.setSensor(response.data);
          Toastr("success", "Sensor Updated Succesfully");
          props.toggle();
        })
        .catch((error) => {
          setwaitingForAxios(false);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else {
      props.toggle();
    }
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ minHeight: "0px" }}>
            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="IP Address" sm={3}>
                IP Address
              </Label>
              <Col sm={9}>
                <input
                  placeholder="IP Address"
                  type="text"
                  // pattern="/[1-9]{1-3}\.[0-9]{1-3}\.[0-9]{1-3}\.[0-9]{1-3}/"
                  onClick={() => trigger("ip_address")}
                  className="form-control"
                  {...register("ip_address", {
                    required: true,
                    onChange: () => {
                      trigger("ip_address");
                    },
                    pattern:
                      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                  })}
                  // onChange={(event) => ipValidator(event.target.value)}
                />
                {errors.ip_address?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
                {errors.ip_address?.type === "pattern" && (
                  <span style={{ color: "red" }}>
                    *Please Provide a valid IP Address
                  </span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="MAC Address" sm={3}>
                MAC Address
              </Label>
              <Col sm={9}>
                <input
                  placeholder="MAC Address"
                  onClick={() => trigger("mac_add")}
                  type="text"
                  className="form-control"
                  {...register("mac_add", {
                    onChange: () => {
                      trigger("mac_add");
                    },
                    required: true,
                    pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-g]{2})$/,
                  })}
                  // onChange={(event) => ipValidator(event.target.value)}
                />
                {errors.mac_add?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
                {errors.mac_add?.type === "pattern" && (
                  <span style={{ color: "red" }}>
                    *Please Provide an Valid MAC Address
                  </span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="Sensor Name" sm={3}>
                Sensor Name
              </Label>
              <Col sm={9}>
                <input
                  onClick={() => trigger("sensor_name")}
                  placeholder="Sensor Name"
                  type="text"
                  className="form-control"
                  {...register("sensor_name", {
                    required: true,
                    // maxLength: 20,
                    onChange: () => {
                      trigger("sensor_name");
                    },
                  })}
                />
                {errors.sensor_name?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
                {errors.sensor_name?.type === "maxLength" && (
                  <span style={{ color: "red" }}>
                    *Camera Name should not exceed 20 characters
                  </span>
                )}
              </Col>
            </FormGroup>

            {props?.item?.manufacturer?.manufacturer_name === "Axis" && (
              <>
                {" "}
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for=" Port" sm={3}>
                    Port
                  </Label>
                  <Col sm={9}>
                    <input
                      placeholder=" Port"
                      onClick={() => trigger("port")}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      {...register("port", {
                        onChange: () => {
                          trigger("port");
                        },
                        required: true,
                        max: 65535,
                        pattern:
                          /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi,
                      })}
                      // onChange={(event) => checkIfValidPortnumber(event.target.value)}
                    />
                    {errors.port?.type === "required" && (
                      <span style={{ color: "red" }}>
                        *This field is required
                      </span>
                    )}
                    {errors.port?.type === "max" && (
                      <span style={{ color: "red" }}>
                        *Port number should not exceed 65535
                      </span>
                    )}

                    {errors.port?.type === "pattern" && (
                      <span style={{ color: "red" }}>
                        *Please Provide an Valid Port No
                      </span>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for="Serial Number" sm={3}>
                    Serial Number
                  </Label>
                  <Col sm={9}>
                    <input
                      onClick={() => trigger("serial_number")}
                      placeholder="Serial Number"
                      type="text"
                      className="form-control"
                      {...register("serial_number", {
                        required: true,
                        onChange: () => {
                          trigger("serial_number");
                        },
                      })}
                      // onChange={(event) => ipValidator(event.target.value)}
                    />
                    {errors.serial_number?.type === "required" && (
                      <span style={{ color: "red" }}>
                        *This field is required
                      </span>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for=" Username" sm={3}>
                    Username
                  </Label>
                  <Col sm={9}>
                    <input
                      placeholder=" Username"
                      onClick={() => trigger("username")}
                      type="text"
                      className="form-control"
                      {...register("username", {
                        onChange: () => {
                          trigger("username");
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.username?.type === "required" && (
                      <span style={{ color: "red" }}>
                        *This field is required
                      </span>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for="Password" sm={3}>
                    Password
                  </Label>
                  <Col sm={9}>
                    <div className="d-flex">
                      <input
                        onClick={() => trigger("password")}
                        placeholder=" Password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        {...register("password", {
                          required: true,
                          onChange: () => {
                            trigger("password");
                          },
                        })}
                        autoComplete="new-password"
                      />
                      <span class="d-flex justify-content-around align-items-center">
                        <div
                          style={{
                            position: "absolute",
                            marginRight: "50px",
                            cursor: "pointer",
                          }}
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </div>
                      </span>
                    </div>
                  </Col>
                </FormGroup>
              </>
            )}
          </div>
          <ModalFooter>
            <Button
              onClick={props.toggle}
              type="button"
              className="w-xs waves-effect waves-light me-1"
              style={{ backgroundColor: "#8B8E9F", borderColor: "#8B8E9F" }}
            >
              Cancel
            </Button>
            <Button
              // disabled={!ipAddress}
              type="submit"
              style={{ backgroundColor: "#1976D2", borderColor: "#1976D2" }}
              className="w-xs waves-effect waves-light me-1"
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Update"
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default EditSensor;
