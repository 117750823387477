// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  counter: null,
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addCounter(state, action) {
      state.counter = action.payload;
    },
    addNewCounter(state, action) {
      state.counter = [...state.counter, action.payload];
    },
    editCounter(state, action) {
      state.counter = state.counter.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    deleteCounter(state, action) {
      state.counter = state.counter.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const { addCounter, addNewCounter, editCounter, deleteCounter } =
  counterSlice.actions;
export default counterSlice.reducer;
