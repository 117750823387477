import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { CardBody, Card } from "reactstrap";
import { tableIcons } from "../../../../helpers/MaterialIcons";
import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../../helpers/Alert";
import axios from "axios";
import Error from "../../../../helpers/Error";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../../assets/lotties/no_search_results.json";
import notfound from "../../../../assets/lotties/not_found_lottie.json";
import {
  addCounter,
  deleteCounter,
  editCounter,
} from "../../../../store/counterSlice";
import LinkIcon from "@mui/icons-material/Link";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Navigate, useNavigate } from "react-router-dom";
import EditCounterLine from "./EditCounterLine";
export const Counter = ({ id }) => {
  const counter = useSelector((state) => state.counterSlice.counter);
  const [filterEnabled] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      // const url = `/abaci_count/add_counter_line`;
      const url = `/abaci_count/zones/counterassignedtozone?zone=${id}`;
      axios
        .get(url)
        .then((response) => {
          dispatch(addCounter(response.data));
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(addCounter([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const counterData =
    counter !== null
      ? counter.map((data, index) => ({
          ...data,
          sl_no: index + 1,
          counter_name: data?.counter_line?.line_name
            ? data.counter_line?.line_name
            : "-",
          sensor_name: data?.sensor?.sensor_name
            ? data.sensor.sensor_name
            : "-",
          counter_direction: data?.counter_direction
            ? data.counter_direction
            : "-",
          follow_sensor_direction: data?.follow_sensor_direction ? "Yes" : "No",
        }))
      : [];

  const UpdateHandler = (counter_id) => {
    const url = `/abaci_count/zones/counterassignedtozone/${counter_id}`;
    const data = { counter_line: null };
    axios
      .patch(url, data)
      .then((response) => {
        Toastr("success", " Counter unassigned from the zone Succesfully");
        setLoader(false);
        dispatch(editCounter(response.data));
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const columns = [
    { title: "SL No", field: "sl_no" },
    { title: "Sensor Name", field: "sensor_name" },
    { title: "Counter Name", field: "counter_name" },
    { title: "Follow sensor direction", field: "follow_sensor_direction" },
  ];

  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  };
  return (
    <>
      {editModalShow && (
        <EditCounterLine
          title={"Reassign Counter Line"}
          isOpen={editModalShow}
          toggle={editModalToggle}
          item={itemToBeEdited}
        />
      )}

      <Card style={{ minHeight: "525px" }}>
        <CardBody>
          <div
            style={{
              overflowY: "auto",
            }}
          >
            <MaterialTable
              style={{ zIndex: "0" }}
              //   onSearchChange={handleSearch}
              title=""
              columns={columns}
              data={counterData}
              isLoading={loader}
              onRowClick={(event, rowData) =>
                navigate(`/sensordetails/${rowData.sensor.id}`)
              }
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <div style={{ height: "300px" }}>
                      {!loader && (
                        <>
                          {" "}
                          <Player
                            autoplay
                            loop
                            src={searchActive ? lottie : notfound}
                            style={{
                              height: "200px",
                              width: "170px",
                            }}
                          />
                          <span style={{ fontSize: "13px" }}>
                            {searchActive
                              ? "Oops! No results found !"
                              : " Oops! No Counter have been added. Please add your Counter to begin counting"}
                          </span>
                        </>
                      )}
                    </div>
                  ),
                },
              }}
              onSearchChange={handleSearch}
              actions={[
                // {
                //   icon: FilterList,
                //   tooltip: filterEnabled ? "Disable Filter" : "Enable Filter",
                //   isFreeAction: true,
                //   onClick: (event) => {
                //     setFilterEnabled((state) => !state);
                //   },
                // },

                // {
                //   icon: Edit,
                //   tooltip: "Edit Counter",
                //   onClick: (event, rowData) => {
                //     setItemToBeEdited(rowData);
                //     editModalToggle();
                //   },
                // },

                (rowData) => ({
                  icon: LinkOffIcon,
                  tooltip: "Unassign Counter Line ",
                  onClick: (event, rowData) => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "This action will remove the counter assignment from the zone, resulting in the exclusion of the sensor count from the zone's overall count. Are you sure you want to proceed ?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Unassign it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        UpdateHandler(rowData.id, "Deleted");
                        // deleteClientData(rowData?.id)
                      }
                    });
                    // setDeleteModal({ id: rowData?.id, toggle: true });
                  },
                  hidden: rowData.counter_line === null,
                }),
                (rowData) => ({
                  icon: LinkIcon,
                  tooltip: "Reassign Counter Line",
                  onClick: (event, rowData) => {
                    setItemToBeEdited(rowData);
                    editModalToggle();
                  },
                  hidden: rowData.counter_line !== null,
                }),
              ]}
              icons={tableIcons}
              options={{
                exportFileName: "Counter",
                exportAllData: true,
                actionsColumnIndex: -1,
                exportButton: true,
                filtering: filterEnabled,
                debounceInterval: 500,
                search: true,
              }}
            />
          </div>

          {/* </Row> */}
        </CardBody>
      </Card>
    </>
  );
};
