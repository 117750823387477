import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import { useDispatch } from "react-redux";
import { addNewSite } from "../../../store/siteSlice";

function AddSite(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("site_name", data.site_name);
    formData.append("rental_space", data.rental_space);
    formData.append("total_capacity", data.total_capacity);
    formData.append("threshold", data.threshold);
    formData.append("end_time", data.end_time);
    formData.append("start_time", data.start_time);

    setwaitingForAxios(true);

    const url = "/abaci_count/site";

    axios
      .post(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        dispatch(addNewSite(response.data));
        Toastr("success", "New Site  Added Succesfully");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Site Name" sm={3}>
              Site Name
            </Label>
            <Col sm={9}>
              <input
                placeholder="Site Name"
                type="text"
                className="form-control"
                {...register("site_name", {
                  required: true,
                })}
              />
              {errors.site_name?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Total Area" sm={3}>
              Total Area(sqm)
            </Label>
            <Col sm={9}>
              <input
                placeholder="Total Area"
                type="number"
                className="form-control"
                onWheel={(e) => e.target.blur()}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                {...register("rental_space", {
                  required: true,
                })}
              />
              {errors.rental_space?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Total Capacity(Person)" sm={3}>
              Total Capacity(Person)
            </Label>
            <Col sm={9}>
              <input
                placeholder=" Total Capacity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                className="form-control"
                {...register("total_capacity", {
                  required: true,
                })}
              />
              {errors.total_capacity?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Allowed Capacity(Person)" sm={3}>
              Allowed Capacity(Person)
            </Label>
            <Col sm={9}>
              <input
                placeholder="Allowed Capacity"
                type="number"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                onWheel={(e) => e.target.blur()}
                className="form-control mt-2"
                {...register("threshold", {
                  required: true,
                })}
              />
              {errors.threshold?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Start Time" sm={3}>
              Start Time
            </Label>
            <Col sm={9}>
              <input
                placeholder="Start Time"
                type="time"
                onWheel={(e) => e.target.blur()}
                className="form-control"
                {...register("start_time", {
                  required: true,
                })}
              />
              {errors.start_time?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="End Time" sm={3}>
              End Time
            </Label>
            <Col sm={9}>
              <input
                placeholder="End Time"
                type="time"
                onWheel={(e) => e.target.blur()}
                className="form-control"
                {...register("end_time", {
                  required: true,
                })}
              />
              {errors.end_time?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          {/* <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Time Interval" sm={3}>
              Time Interval
            </Label>
            <Col sm={9}>
              <select
                className="form-control"
                {...register("time_interval", {
                  // required: true,
                })}
              >
                <option value=""> --Select Intervals--</option>

                <option value="All Day">All Day</option>
                <option value="1 Hour">1 Hour</option>
                <option value="30 Minutes">30 Minutes</option>
              </select>
              {errors.time_interval?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup> */}

          <ModalFooter>
            {/*                             
                            <Button
                                type="button"
                                className="w-xs waves-effect waves-light me-1"
                                style={{backgroundColor:'#41C384',borderColor:'#41C384'}}
                                
                            >
                                Get Cam Info
                            </Button> */}
            <Button
              type="submit"
              style={{ backgroundColor: "#5664D2", borderColor: "#5664D2" }}
              className="w-xs waves-effect waves-light me-1"
              // onClick={() => {
              //     onSubmit();
              // }}
              disabled={waitingForAxios}
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                " Submit"
              )}
            </Button>
            <Button
              type="button"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default AddSite;
