import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import Error from "../../../helpers/Error";
import { addNewUser } from "../../../store/usersSlice";
import { Toastr } from "../../../helpers/Alert";

function AddUser(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, seterrorMessage] = useState(null);
  const [errorMessege1, seterrorMessage1] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let formData = new FormData();

    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("contact_number", data.contact_number);
    formData.append("password", data.password);
    formData.append("department", data.department);
    formData.append("designation", data.designation);
    formData.append("user_type", data.user_type);

    if (data.user_type !== "Admin") {
      formData.append("is_admin", "False");
    } else {
      formData.append("is_admin", "True");
    }

    setwaitingForAxios(true);

    const url = "/users_api/create_account";

    axios
      .post(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        dispatch(addNewUser(response.data.Account_info));
        Toastr("success", "New User Added Succesfully");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  useEffect(() => {
    if (password) {
      const re = new RegExp(
        `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`
      );
      const isOk = re.test(password);

      if (isOk) {
        seterrorMessage1(null);
        seterrorMessage("Passwords should match");
        if (password === confirmPassword) {
          seterrorMessage(null);
        }
      } else {
        seterrorMessage1(
          "The password should contain minimum 8 characters and maximum of 20 with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
        );
      }
    } else {
      seterrorMessage1(null);
      seterrorMessage(null);
    }
  }, [password, confirmPassword]);

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" First Name" sm={3}>
              First Name
            </Label>
            <Col sm={9}>
              <input
                placeholder=" First Name"
                type="text"
                className="form-control"
                {...register("first_name", {
                  required: true,
                })}
              />
              {errors.first_name?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Last Name" sm={3}>
              Last Name
            </Label>
            <Col sm={9}>
              <input
                placeholder=" Last Name"
                type="text"
                className="form-control"
                {...register("last_name", {
                  // required: true,
                })}
              />
              {errors.last_name?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Department" sm={3}>
              Department
            </Label>
            <Col sm={9}>
              <input
                placeholder=" Department"
                type="text"
                className="form-control"
                {...register("department", {
                  // required: true,
                })}
              />
              {errors.department?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Designation" sm={3}>
              Designation
            </Label>
            <Col sm={9}>
              <input
                placeholder=" Designation"
                type="text"
                className="form-control"
                {...register("designation", {
                  // required: true,
                })}
              />
              {errors.designation?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Email / Username" sm={3}>
              Email / Username
            </Label>
            <Col sm={9}>
              <input
                placeholder="Email / Username"
                type="email"
                className="form-control"
                {...register("email", {
                  required: true,
                })}
              />
              {errors.email?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Phone" sm={3}>
              Phone
            </Label>
            <Col sm={9}>
              <input
                placeholder="Phone"
                type="number"
                onWheel={(e) => e.target.blur()}
                className="form-control"
                {...register("contact_number", {
                  // required: true,
                })}
              />
              {errors.contact_number?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Password" sm={3}>
              Password
            </Label>
            <Col sm={9}>
              <input
                placeholder="Password"
                type="password"
                className="form-control"
                {...register("password", {
                  required: true,
                  onChange: (event) => {
                    setPassword(event.target.value);
                  },
                  pattern:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                })}
              />
              {errors.password?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
              <span style={{ color: "red" }}>
                {errorMessege1 ? errorMessege1 : null}
              </span>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Confirm Password" sm={3}>
              Confirm Password
            </Label>
            <Col sm={9}>
              <input
                placeholder="Confirm Password"
                type="password"
                className="form-control"
                {...register("confirm_password", {
                  required: true,
                  onChange: (event) => {
                    setConfirmPassword(event.target.value);
                  },
                })}
              />
              {errors.confirm_password?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
              <span style={{ color: "red" }}>
                {errorMessage ? errorMessage : null}
              </span>
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="User Type" sm={3}>
              User Type
            </Label>
            <Col sm={9}>
              <select
                className="form-control"
                {...register("user_type", {
                  required: true,
                })}
              >
                <option value=""> --Select User Type--</option>

                <option value="Admin">Admin</option>
                <option value="Viewer">Viewer</option>
                <option value="User">User</option>
              </select>

              {errors.user_type?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <ModalFooter>
            <Button
              type="button"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
            <Button
              type="submit"
              style={{ backgroundColor: "#1976D2", borderColor: "#1976D2" }}
              className="w-xs waves-effect waves-light me-1"
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                " Submit"
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default AddUser;
