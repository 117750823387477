import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";

import axios from "axios";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
// import { useDispatch } from 'react-redux';
import CreatableSelect from "react-select/creatable";
import { useParams } from "react-router-dom";
function EditCounter(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [selectedDirection1, setSelectedDirection1] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [direction] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);

  const onSubmit = (data) => {
    let formData = new FormData();

    formData.append("is_site_boundary_line", selectedDirection1.value);
    setwaitingForAxios(true);

    const url = `/abaci_count/sensor_counter_line/${data.id}`;
    axios
      .patch(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        props.tableRef.current.onQueryChange();
        Toastr("success", " Counter Updated Succesfully");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  // useEffect(() => {
  //   const url = `/abaci_count/sensors?limited=${true}&site=${selected_site.id}`;

  //   axios
  //     .get(url)
  //     .then((response) => {
  //       const temp = response.data.map((data) => ({
  //         ...data,
  //         label: data.sensor_name,
  //         value: data.sensor_name,
  //       }));
  //       setSensor(temp);
  //       // dataValidator();
  //       setLoader((state) => ({ ...state, loader1: false }));

  //       //FUNCTION CALLED INORDER TO FETCH COUNTER DATA WITH RESPECT TO SENSOR
  //       counterDataFetcher();
  //     })
  //     .catch((error) => {
  //       setLoader((state) => ({ ...state, loader1: false }));
  //       const err_msg = Error(error);
  //       Toastr("error", err_msg);
  //     });
  // }, []);

  // const handleSensor = (values) => {
  //   setSelectedCounter(null);
  //   setSelectedSensor(values);
  //   // console.log(values.manufacturer.manufacturer_name);
  //   if (values && values.manufacturer.manufacturer_name === "Axis") {
  //     setCounter([{ label: "Line1", value: "Line1" }]);
  //   } else {
  //     setCounter([
  //       { label: "Line1", value: "Line1" },
  //       { label: "Line2", value: "Line2" },
  //       { label: "Line3", value: "Line3" },
  //       { label: "Line4", value: "Line4" },
  //     ]);
  //   }
  // };

  useEffect(() => {
    if (props.item) {
      reset({ ...props.item });
      setSelectedDirection1({
        label: props.item.is_site_boundary_line ? "Yes" : "No",
        value: props.item.is_site_boundary_line,
      });
    }
  }, [props]);

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Line" sm={3}>
              Line Name
            </Label>
            <Col sm={9}>
              <input
                placeholder="Line"
                type="text"
                disabled
                className="form-control"
                {...register("line_name", {
                  required: true,
                })}
              />
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for=" Site entry/exit Sensor " sm={3}>
              Site entry/exit Sensor
            </Label>
            <Col sm={9}>
              <CreatableSelect
                placeholder=" Yes"
                isClearable
                isValidNewOption={() => false}
                promptTextCreator={() => false}
                onChange={(values) => setSelectedDirection1(values)}
                options={direction}
                isMulti={false}
                value={selectedDirection1}
              />
            </Col>
          </FormGroup>
          {/* <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for=" Counter Direction" sm={3}>
                Counter Direction
              </Label>
              <Col sm={9}>
                <CreatableSelect
                  placeholder=" Counter Direction"
                  isClearable
                  isValidNewOption={() => false}
                  promptTextCreator={() => false}
                  onChange={(values) => setSelectedDirection1(values)}
                  options={direction}
                  isMulti={false}
                  value={selectedDirection1}
                />
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginBottom: "10px" }}>
              <Label for="Assigned Direction" sm={3}>
                Assigned Direction
              </Label>
              <Col sm={9}>
                <CreatableSelect
                  placeholder="Assigned Direction"
                  isClearable
                  isValidNewOption={() => false}
                  promptTextCreator={() => false}
                  onChange={(values) => setSelectedDirection2(values)}
                  options={direction}
                  isMulti={false}
                  value={selectedDirection2}
                />
              </Col>
            </FormGroup> */}

          <ModalFooter>
            <Button
              type="button"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
            <Button
              type="submit"
              style={{ backgroundColor: "#5664D2", borderColor: "#5664D2" }}
              className="w-xs waves-effect waves-light me-1"
              // onClick={() => {
              //     onSubmit();
              // }}
              disabled={waitingForAxios}
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                " Submit"
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default EditCounter;
