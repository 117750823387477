import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./layout";
import authSlice from "./auth";

import cameraSlice from "./cameraSlice";
import tokenSlice from "./token";
import siteSlice from "./siteSlice";
import userSlice from "./usersSlice";
import zoneSlice from "./zoneSlice";
import notificationSlice from "./notificationsSlice";
import counterSlice from "./counterSlice";
const store = configureStore({
  reducer: {
    layoutSlice: layoutSlice,
    authSlice: authSlice,
    cameraSlice: cameraSlice,
    tokenSlice: tokenSlice,
    siteSlice: siteSlice,
    userSlice: userSlice,
    zoneSlice: zoneSlice,
    counterSlice: counterSlice,
    notificationSlice,
    notificationSlice,
  },
});

export default store;
