// import { ErrorAlert } from "./Alert"
// import Error from "./Error"
// import { useDispatch } from 'react-redux';

import Cookies from "js-cookie";
import moment from "moment";
import { baseURL } from "./baseURL";
export const APP_NAME = "abacicount";
export const Color = [
  "#1cbb8c",
  "#008FFB",
  "#FF0000",
  "#A58CFF",
  "#5664d2",
  "#260e12",
  "#eeb902",
  "#ABCB35",
];

export const Colors = [
  "#008FFB",
  "#1cbb8c",
  "#FF0000",
  "#A58CFF",
  "#5664d2",
  "#260e12",
  "#eeb902",
  "#ABCB35",
];

const token = Cookies.get("login_token");
export const post_url = baseURL + "/abacicount/cloudaccesstoclient";
export const payload = {
  request_type: "get",
  is_public_url: false,
  cloud_access_unique_number: 3768,
  token: token,
};

export const csvLimit = 3000;
export const pdfLimit = 5000;

export const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
};

export const pageSizeOptions = [5, 10, 20, 50];

export const yearranges = {
  "Current Month": [moment().startOf("month"), moment()],
  "Current Year": [moment().startOf("year"), moment()],
  "Last 30 Days": [moment().subtract(30, "days"), moment()],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
  "Last 365 Days": [moment().subtract(364, "days"), moment()],
};
// export const updateHandler = (id,method,redux,url) =>{
//     const dispatch=useDispatch();

//     const urls=url
//     axios.patch(urls,{status:method})
//           .then((response) =>{
//             SuccessAlert(`Credit ${method} Successfully!`)
//             dispatch(redux(response.data))
//             if(response.data.status === 'Approved'){
//             dispatch(redux(response.data.new_balance))
//             }
//             else{
//               dispatch(redux(response.data.previous_balance))

//             }
//           })
//           .catch((error) =>{
//             const err_msg=Error(error)
//             ErrorAlert(err_msg)
//           })
//    }
// function cc_format(value) {
//   const v = value
//     .replace(/\s+/g, "")
//     .replace(/[^0-9]/gi, "")
//     .substr(0, 16);
//   const parts = [];

//   for (let i = 0; i < v.length; i += 4) {
//     parts.push(v.substr(i, 4));
//   }

//   return parts.length > 1 ? parts.join("-") : value;
// }

export function truncateString(str) {
  let temp = str.slice(0, 40) + "...";
  return temp;
}

// function convertIPAddress(ipAddress) {
//   // Split the IP address into an array of numbers
//   const numbers = ipAddress.split(".");

//   // Initialize an empty result string
//   let result = "";

//   // Loop through the numbers
//   for (let i = 0; i < numbers.length; i++) {
//     // Add the current number
//     result += numbers[i];

//     // Add underscores based on the position
//     for (let j = 0; j < i + 2; j++) {
//       result += "_";
//     }

//     // Add a period (.) except for the last number
//     if (i < numbers.length - 1) {
//       result += ".";
//     }
//   }

//   return result;
// }
