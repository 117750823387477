import "./assets/scss/theme.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
// import { WebsocketProvider } from "./Context/WebSocketContext";
function App() {
  return (
    // <WebsocketProvider>
    <Router>
      <Routes />
    </Router>
    // </WebsocketProvider>
  );
}

export default App;
