import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { tableIcons } from "../../../../helpers/MaterialIcons";
import Moments from "../../../../helpers/Moment";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../../assets/lotties/no_search_results.json";
import notfound from "../../../../assets/lotties/not_found_lottie.json";
import { exportData } from "../../../../helpers/ExportData";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
import Edit from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
export const DetailsTable = ({
  columns,
  tableRef,
  activeTab,
  editCounterModalToggle,
  sensorData,
}) => {
  const totalRecordsCount = useRef(0);
  const urlBackup = useRef();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [muiTableKey, setMuiTableKey] = useState(0);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [loader, setloading] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const search = useRef("");
  useEffect(() => {
    if (activeTab) {
      setloading(true);
      setCurrentPage(0);
      if (search.current !== "") {
        setMuiTableKey((prev) => prev + 1);
      } else {
        tableRef.current.onQueryChange({ page: 0, filters: [] });
      }
    }
  }, [activeTab]);

  const tableData = [
    {
      label: "People Count",
      url: "sensor_input_log_list",
      column: [
        {
          title: "Sl No",
          field: "serialNumber",
          render: (rowData) => (
            <>
              {rowData.tableData.id !== undefined
                ? rowData.tableData.id + 1 + pageSize * currentPage
                : ""}
            </>
          ),

          sorting: false, // Disable sorting for this column
        },
        {
          title: "Line Order Number",
          field: "line_order_number",
          render: (rowData) => (
            <>
              {rowData?.sensor_counter_line?.line_order_number
                ? rowData.sensor_counter_line.line_order_number
                : "-"}
            </>
          ),
        },
        {
          title: "Line Name",
          field: "line_name",
          render: (rowData) => (
            <>
              {rowData?.sensor_counter_line?.line_name
                ? rowData.sensor_counter_line?.line_name
                : "-"}
            </>
          ),
        },
        {
          title: "In",
          field: "adult_in",
          render: (rowData) => <>{rowData.adult_in ? rowData.adult_in : "0"}</>,
        },
        {
          title: "Out",
          field: "adult_out",
          render: (rowData) => (
            <>{rowData?.adult_out ? rowData.adult_out : "0"}</>
          ),
        },
        {
          title: "Occupancy",
          field: "occupancy",
          render: (rowData) => (
            <>
              {rowData?.adult_in && rowData?.adult_out
                ? rowData.adult_in - rowData.adult_out
                : "0"}
            </>
          ),
        },
        // {
        //   title: "Child In",
        //   field: "children_in",
        //   render: (rowData) => (
        //     <>{rowData?.children_in ? rowData.children_in : "0"}</>
        //   ),
        // },
        // {
        //   title: "Child Out",
        //   field: "children_out",
        //   render: (rowData) => (
        //     <>{rowData?.children_out ? rowData.children_out : "0"}</>
        //   ),
        // },
        {
          title: "Time",
          field: "count_time_on_dpu",
          width: "60%",
          render: (rowData) => (
            <>
              {rowData.count_time_on_dpu
                ? Moments(rowData.count_time_on_dpu, "datetimeseconds")
                : "-"}
            </>
          ),
        },
      ],
    },
    {
      label: "Counting lines",
      url: "sensor_counter_line",
      column: [
        {
          title: "Sl No",
          field: "serialNumber",
          render: (rowData) => (
            <>
              {rowData.tableData.id !== undefined
                ? rowData.tableData.id + 1 + pageSize * currentPage
                : ""}
            </>
          ),
          editable: false,
          sorting: false, // Disable sorting for this column
        },
        {
          title: "Line No.",
          field: "line_order_number",
          render: (rowData) => (
            <>{rowData?.line_order_number ? rowData.line_order_number : "-"}</>
          ),
          editable: false,
        },
        {
          title: "Line Name",
          field: "line_name",
          render: (rowData) => (
            <>{rowData?.line_name ? rowData.line_name : "-"}</>
          ),
          editable: false,
        },

        {
          title: "Site entry/exit sensor",
          field: "is_site_boundary_line",
          lookup: { 1: "No", 2: "Yes" },
          render: (rowData) => (
            <>{rowData?.is_site_boundary_line ? "Yes" : "No"}</>
          ),
        },
      ],
    },

    {
      label: "Activity log",
      url: "sensor_activity_log_list",
      column: [
        {
          title: "Sl No",
          field: "serialNumber",
          render: (rowData) => (
            <>
              {rowData.tableData.id !== undefined
                ? rowData.tableData.id + 1 + pageSize * currentPage
                : ""}
            </>
          ),
          sorting: false, // Disable sorting for this column
        },
        {
          title: "Remarks",
          field: "remarks",
          render: (rowData) => <>{rowData?.remarks ? rowData.remarks : "-"}</>,
        },
        {
          title: "Time",
          field: "time",
          render: (rowData) => (
            <>
              {" "}
              {rowData?.event_time
                ? Moments(rowData.event_time, "datetimeseconds")
                : "-"}
            </>
          ),
        },
      ],
    },
    {
      label: "Connection log",
      url: "sensor_connection_log_list",
      column: [
        {
          title: "Sl No",
          field: "serialNumber",
          render: (rowData) => (
            <>
              {rowData.tableData.id !== undefined
                ? rowData.tableData.id + 1 + pageSize * currentPage
                : ""}
            </>
          ),
          sorting: false, // Disable sorting for this column
        },
        {
          title: "Remarks",
          field: "remarks",
        },
        {
          title: "Time",
          field: "event_time",
          render: (rowData) => (
            <>
              {" "}
              {rowData.event_time
                ? Moments(rowData.event_time, "datetimeseconds")
                : "-"}
            </>
          ),
        },
      ],
    },
  ];

  const addCounterLine = () => {
    const url = `/abaci_count/sensor_counter_line?sensor=${sensorData.id}`;
    axios
      .post(url)
      .then((response) => {
        Toastr("success", " Counter Line Added Succesfully");
        tableRef.current.onQueryChange();
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const UpdateHandler = (id) => {
    const url = `/abaci_count/sensor_counter_line/${id}`;
    axios
      .delete(url)
      .then((response) => {
        Toastr("success", " Counter Line Deleted Succesfully");
        tableRef.current.onQueryChange();
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <div
      style={{
        overflowY: "auto",
      }}
    >
      <MaterialTable
        style={{ zIndex: "0" }}
        title=""
        columns={tableData[activeTab].column}
        tableRef={tableRef}
        isLoading={loader}
        key={muiTableKey}
        // editable={
        //   activeTab === 1
        //     ? {
        //         onRowUpdate: (newData, oldData) => {
        //           const url = `/abaci_count/sensor_counter_line/${newData.id}`;
        //           axios
        //             .patch(url, {
        //               is_site_boundary_line: newData.is_site_boundary_line,
        //             })
        //             .then((response) => {
        //               // Toastr("success", " Counter Deleted Succesfully");
        //               // dispatch(deleteCounter(counter_id));
        //             })
        //             .catch((error) => {
        //               const err_msg = Error(error);
        //               Toastr("error", err_msg);
        //             });
        //         },

        //         initialEditValue: {
        //           is_site_boundary_line: 1, // Set the default selected value for the lookup field
        //         },
        //       }
        //     : undefined // Set to undefined when activeTab is not 0
        // }
        localization={{
          body: {
            emptyDataSourceMessage: (
              <div style={{ height: "300px" }}>
                {!loader && (
                  <>
                    <Player
                      autoplay
                      loop
                      src={filterEnabled || searchActive ? lottie : notfound}
                      style={{
                        height: "200px",
                        width: "170px",
                      }}
                    />
                    <span style={{ fontSize: "13px" }}>
                      {filterEnabled || searchActive
                        ? "Oops! No results found !"
                        : `Oops! No ${tableData[activeTab].label} to dispaly.`}
                    </span>
                  </>
                )}
              </div>
            ),
          },
        }}
        // {activeTab!==0
        // editable={{
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {}),
        // }}
        onChangeRowsPerPage={setPageSize}
        onChangePage={(newPage) => setCurrentPage(newPage)}
        data={(query) => {
          // console.log(query)
          return new Promise((resolve, reject) => {
            let orderBy = "";
            // let search = "";
            if (query.orderBy) {
              orderBy =
                query.orderDirection === "asc"
                  ? `&ordering=-${query.orderBy?.field}`
                  : `&ordering=${query.orderBy?.field}`;
            }

            if (query.search) {
              setloading(true);
              setSearchActive(true);
              let data = query.search.split("-");
              const timeRegex = /\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2} (AM|PM)/g;

              // if (query.search.match(timeRegex)) {
              //   let date_time = moment(query.search).format();
              //   date_time = date_time.split("+");
              //   search.current = date_time[0];
              //   // console.log(date_time[0]);
              // }
              if (data.length === 3) {
                let date = moment(query.search).format("YYYY-DD-MM");
                // console.log(date);
                if (date !== "Invalid date") {
                  // console.log("first");
                  search = date;
                }
              } else {
                search.current = query.search;
              }
            } else {
              setloading(true);
              setSearchActive(false);
            }
            let url = `/abaci_count/${
              tableData[activeTab].url
            }?sensor=${id}&limit=${query.pageSize}&offset=${
              query.pageSize * query.page
            }&search=${search.current}${orderBy}`;
            urlBackup.current = url;
            axios
              .get(url)
              .then((response) => {
                totalRecordsCount.current = response.data?.count;
                resolve({
                  data: response.data?.results,
                  page: response.data?.results.length === 0 ? 0 : query.page,
                  totalCount: response.data?.count,
                });
              })
              .catch((error) => {
                let err_msg = Error(error);
                Toastr("error", err_msg);
                reject({
                  data: [],
                  page: query.page,
                  totalCount: 0,
                });
              })
              .finally(() => {
                setloading(false); // Indicate that the query is complete
              });
          });
        }}
        actions={[
          {
            icon: AddBoxIcon,
            tooltip: "Add Counter Line",
            isFreeAction: true,
            onClick: (event) => {
              addCounterLine();
            },
            hidden:
              activeTab !== 1 ||
              sensorData?.manufacturer?.manufacturer_name === "Axis",
          },
          {
            icon: RefreshIcon,
            tooltip: "Refresh",
            isFreeAction: true,
            onClick: (event) => {
              tableRef.current.onQueryChange();
            },
          },

          (rowData) => ({
            icon: DeleteOutlineIcon,
            tooltip: "Delete Counter",
            onClick: (event, rowData) => {
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  UpdateHandler(rowData.id);
                  // deleteClientData(rowData?.id)
                }
              });
            },
            hidden:
              activeTab !== 1 ||
              sensorData?.manufacturer?.manufacturer_name === "Axis",
          }),
          {
            icon: Edit,
            tooltip: "Edit Counter Line",
            onClick: (event, rowData) => {
              // setEditClientModalShow(true)
              // setItemToBeEdited(rowData);
              editCounterModalToggle(rowData);
            },
            hidden: activeTab !== 1,
          },
        ]}
        options={{
          exportFileName: tableData[activeTab].label,
          exportAllData: true,
          actionsColumnIndex: -1,
          exportButton: activeTab === 0,
          // filtering: filterEnabled,
          debounceInterval: 500,
          exportCsv: async () =>
            exportData(
              "csv_download",
              urlBackup.current,
              totalRecordsCount.current,
              tableData[activeTab].label
            ),
          exportPdf: async () =>
            exportData(
              "pdf_download",
              urlBackup.current,
              totalRecordsCount.current,
              tableData[activeTab].label
            ),
        }}
        icons={tableIcons}
      />
    </div>
  );
};
