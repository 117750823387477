import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import Button from "@mui/material/Button";
//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Colors, payload, post_url } from "../../../helpers/Constants";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import moment from "moment";
import Error from "../../../helpers/Error";
import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/not_found_lottie.json";
import { set } from "react-hook-form";
export default function LineGraph(props) {
  const [error, setError] = useState(null);
  const [state, setState] = useState({ series: [], options: {} });
  const [loader, setLoader] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeButton, setActiveButton] = useState(0);
  const [dateAndTime, setDateAndTime] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().add(1, "days").format("YYYY-MM-DD"),
    start_time: "",
    end_time: "",
  });
  const [tabValue, setTabValue] = useState("Entry over time");
  const selected_site = useSelector((state) => state.siteSlice.selected_site);
  const selected_zone = useSelector((state) => state.zoneSlice.activeZone);

  const siteOptions = ["day", "week", "month"];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    console.log(event);
  };
  //graph
  const graphDataHandler = (data) => {
    // setState({ series: [], options: {} });
    let url = "";

    let dataToBeSend = {
      payload: { ...data, entry_type: tabValue },
      ...payload,
      request_type: "post",
    };
    if (props.type === "Dashboard") {
      dataToBeSend = {
        ...dataToBeSend,
        site: selected_site?.id,
      };
    } else {
      dataToBeSend = {
        ...dataToBeSend,
        zone: Number(selected_zone),
        url: "/abaci_count/zone_live_count_graph",
      };
    }

    setLoader(true);
    axios
      .post(post_url, dataToBeSend)
      .then((res) => {
        if (res.data.y_axis.length !== 0) {
          setState({
            series: res.data.x_axis,
            options: {
              chart: {
                zoom: { enabled: !1 },
                toolbar: {
                  show: true,

                  export: {
                    svg: {
                      filename: "Total Count",
                    },
                    png: {
                      filename: "Total Count",
                    },
                    csv: {
                      filename: "Total Count",
                    },
                  },
                },
              },

              colors: Colors,
              dataLabels: { enabled: false },
              stroke: {
                width: [3, 3, 3, 3, 3, 3, 3, 3],
                curve: "straight",
              },
              title: {
                align: "left",
                style: { fontWeight: "normal", fill: "#373d3f", opacity: 1 },
              },
              grid: {
                row: { colors: ["transparent", "transparent"], opacity: 0.2 },
                borderColor: "#f1f1f1",
              },
              markers: { style: "inverted", size: 6 },
              xaxis: {
                categories: res.data.y_axis,
                title: { text: "Time Range" },
              },
              yaxis: {
                title: { text: "People count" },
              },
              legend: {
                position: "bottom",
                horizontalAlign: "center",
                floating: !2,
                offsetY: 8,
                offsetX: -5,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: { toolbar: { show: true } },
                    legend: { show: !1 },
                  },
                },
              ],
            },
          });
          setError(null);
        } else {
          setError("No data to dispaly !");
          setState({ series: [], options: {} });
        }
        setLoader(false);
      })
      .catch((error) => {
        setState({ series: [], options: {} });
        setLoader(false);
        setError(Error(error));
      });
  };

  useEffect(() => {
    if (selected_site || selected_zone) {
      graphDataHandler({
        start_date: dateAndTime.start_date,
        end_date: moment(dateAndTime.start_date)
          .add(1, "days")
          .format("YYYY-MM-DD"),
        start_time: "",
        end_time: "",
        site_type_filter: siteOptions[activeButton],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, selected_site, selected_zone]);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Update window width on resiz

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //today-week-month-button
  const buttonHandler = (id) => {
    setActiveButton(id);
    localStorage.setItem("site_type_filter", siteOptions[id]);

    setLoader(true);
    graphDataHandler({
      start_date: dateAndTime.start_date,
      end_date: moment(dateAndTime.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD"),

      start_time: dateAndTime.start_time,
      end_time: dateAndTime.end_time,
      site_type_filter: siteOptions[id],
    });
  };

  const dateAndTimeHandler = (value, type) => {
    setDateAndTime({
      ...dateAndTime,
      [type]: value,
    });
  };

  const filterWithDateAndTime = () => {
    setLoader(true);
    // console.log(dateAndTime);
    graphDataHandler({
      start_date: dateAndTime.start_date,
      end_date: moment(dateAndTime.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      start_time: dateAndTime.start_time,
      end_time: dateAndTime.end_time,
      site_type_filter: siteOptions[activeButton],
    });
  };

  let styles = {
    fontSize: "0.85rem",
    textTransform: "none",
  };
  return (
    <React.Fragment>
      <Container fluid>
        <Card
          style={{
            minHeight: `${
              windowHeight > 1000
                ? windowHeight - 390
                : windowWidth < 600
                ? 800
                : 550
            }px`,

            marginLeft: "7px",
            marginRight: "7px",
          }}
        >
          <CardBody>
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <Box
                sx={{ width: "100%", marginBottom: "5px" }}
                className="d-flex justify-content-center"
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="primary tabs example"
                >
                  <Tab
                    label="Entry over time"
                    style={styles}
                    value={"Entry over time"}
                    disabled={loader}
                  />
                  <Tab
                    label="Exit over time"
                    style={styles}
                    value={"Exit over time"}
                    disabled={loader}
                  />
                  <Tab
                    label="Occupancy over time"
                    style={styles}
                    value={"Occupancy over time"}
                    disabled={loader}
                  />
                </Tabs>
              </Box>
            </div>
            <div className="graph-heading-container">
              <h4 className="card-title mb-4">{tabValue}</h4>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  minWidth: "20%",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  style={{
                    borderRadius: ".25rem",
                    textTransform: "capitalize",
                    color: `${activeButton !== 0 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 0 ? "" : "#1976D2"}`,
                  }}
                  onClick={() => buttonHandler(0)}
                  className="dashboard-button-group-style"
                  disabled={loader}
                >
                  Day
                </Button>
                <Button
                  style={{
                    borderRadius: ".25rem",
                    textTransform: "capitalize",
                    color: `${activeButton !== 1 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 1 ? "" : "#1976D2"}`,
                  }}
                  className="dashboard-button-group-style"
                  onClick={() => buttonHandler(1)}
                  disabled={loader}
                >
                  Week
                </Button>
                <Button
                  style={{
                    borderRadius: ".25rem",
                    textTransform: "capitalize",
                    color: `${activeButton !== 2 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 2 ? "" : "#1976D2"}`,
                  }}
                  className="dashboard-button-group-style"
                  onClick={() => buttonHandler(2)}
                  disabled={loader}
                >
                  Month
                </Button>
              </div>
            </div>

            {/* <div className="d-flex  ">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "20px",
                  gap: "15px",
                }}
              >
                <Col xl={1.2}>
                  <input
                    style={{
                      cursor: "pointer",
                    }}
                    placeholder="Date"
                    type="date"
                    className="form-control"
                    value={dateAndTime.start_date}
                    onChange={(e) =>
                      dateAndTimeHandler(e.target.value, "start_date")
                    }
                    disabled={activeButton !== 0}
                  />
                </Col>
                <Col xl={1.2}>
                  <Tooltip arrow title="Enter start time">
                    <input
                      style={{ cursor: "pointer" }}
                      placeholder=""
                      type="time"
                      className="form-control "
                      value={dateAndTime.start_time}
                      onChange={(e) =>
                        dateAndTimeHandler(e.target.value, "start_time")
                      }
                      disabled={activeButton !== 0}
                    />
                  </Tooltip>
                </Col>

                <Col xl={1.2}>
                  <Tooltip arrow title="Enter end time">
                    <input
                      style={{
                        cursor: "pointer",
                      }}
                      placeholder=""
                      type="time"
                      className="form-control "
                      value={dateAndTime.end_time}
                      onChange={(e) =>
                        dateAndTimeHandler(e.target.value, "end_time")
                      }
                      disabled={activeButton !== 0}
                    />
                  </Tooltip>
                </Col>
                <Col xl={1.4}>
                  <Tooltip arrow title="Click to filter">
                    <Button
                      type="button"
                      className="waves-effect waves-light me-1"
                      style={{
                        border: "1px solid #D8DDE1",
                        height: "40px",
                        borderRadius: "5px",
                        backgroundColor: activeButton !== 0 ? "#EFF2F7" : "",
                      }}
                      onClick={filterWithDateAndTime}
                      disabled={activeButton !== 0}
                    >
                      <RefreshIcon style={{ color: "#677486" }} />
                    </Button>
                  </Tooltip>
                </Col>
              </div>
            </div> */}

            <div id="donut-chart" className="apex-charts">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="bar"
                height={`${windowHeight > 1000 ? windowHeight - 550 : 370}px`}
              />
              {error ? (
                <>
                  <p className="text-center" style={{ marginLeft: "30px" }}>
                    {error}
                  </p>
                </>
              ) : null}
            </div>

            {loader && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              >
                <GraphLoader />
              </div>
            )}
            {/* {selected_site === null && (
              <div
                style={{
                  position: "absolute",
                  top: "45",
                  left: "50%",
                  transform: "translate(-50%,-45%)",
                }}
              >
                <>
                  <Player
                    autoplay
                    loop
                    src={lottie}
                    style={{
                      height: "200px",
                      width: "200px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>
                    Oops! No Site have been selected. Please select a site
                  </span>
                </>
              </div>
            )} */}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
}
