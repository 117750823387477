import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import { baseURL } from "./helpers/baseURL";
import Cookies from "js-cookie";

axios.interceptors.request.use((request) => {
  // const url =

  const token = Cookies.get("login_token");

  request.baseURL = baseURL;
  // request.timeout = 20000;
  request.headers.Authorization = "Bearer " + token;
  // request.baseURL = 'http://' + localStorage.getItem('tenant')+'.abacisignage.com';

  return request;
});

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
