import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { APP_NAME } from "../../../helpers/Constants";
import axios from "axios";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import moment from "moment";
import MiniWidgets from "../Dashboard/MiniWidgets";
import BarGraph from "../Dashboard/BarGraph";
import HeatMapChart from "../Dashboard/HeatMapChart";
import LineGraph from "../Dashboard/LineGraph";
import AddCounter from "./Counter/AddCounter";
import { useParams } from "react-router-dom";
import { Counter } from "./Counter/Counter";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import EditZone from "./EditZone";
import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import { setActiveZone } from "../../../store/zoneSlice";
import { useDispatch } from "react-redux";

export default function ZoneDetailsPage() {
  const [dateAndTime, setDateAndTime] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [zoneData, setZoneData] = useState({});
  const [editModalShow, setEditModalShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [peopleCount, setPeopleCount] = useState([
    {
      id: 1,
      icon: "ri-user-shared-fill",
      title: "People In",
      value: 0,
      desc: "Total People In",
    },
    {
      id: 2,
      icon: "ri-user-unfollow-fill",
      title: "People Exit",
      value: 0,
      desc: "Total People Exit",
    },
    {
      id: 3,
      icon: "ri-team-fill",
      title: "Occupancy",
      value: 0,
      desc: " Total Occupancy",
    },
    {
      id: 4,
      icon: "ri-calendar-event-fill",
      title: "Busiest Day",
      desc: " ",
      day: "----",
      day_letters: "",
    },
    {
      id: 5,
      icon: "ri-time-fill",
      title: "Busiest Hour",
      desc: " ",
      hour: "----",
    },
  ]);

  useEffect(() => {
    return () => {
      setPeopleCount([
        {
          id: 1,
          icon: "ri-user-shared-fill",
          title: "People In",
          value: 0,
          desc: "Total People In",
        },
        {
          id: 2,
          icon: "ri-user-unfollow-fill",
          title: "People Exit",
          value: 0,
          desc: "Total People Exit",
        },
        {
          id: 3,
          icon: "ri-team-fill",
          title: "Occupancy",
          value: 0,
          desc: " Total Occupancy",
        },
        {
          id: 4,
          icon: "ri-calendar-event-fill",
          title: "Busiest Day",
          desc: "Current Time",

          day: "----",
          day_letters: "",
        },
        {
          id: 5,
          icon: "ri-time-fill",
          title: "Busiest Hour",

          hour: "-- --",
          desc: "Current Time",
        },
      ]);
      dispatch(setActiveZone(null));
    };
  }, []);

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "", link: "#" },
    ],
  });

  useEffect(() => {
    if (id) {
      dispatch(setActiveZone(id));
      const urls = `/abaci_count/zones/${id}`;
      axios
        .get(urls)
        .then((res) => {
          setZoneData(res.data);
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });

      const url = `/abaci_count/zone_busiest_day_hour?zone=${id}`;
      axios
        .get(url)
        .then((res) => {
          setDateAndTime(res.data);
          setLoader(false);
          setPeopleCount([
            {
              icon: "ri-user-shared-fill",
              title: " People In",
              value: res.data?.zone_count_in ? res.data.zone_count_in : 0,
              desc: "Total People In",
            },
            {
              icon: "ri-user-unfollow-fill",
              title: "People Exit",
              value: res.data?.zone_count_out ? res.data.zone_count_out : 0,
              desc: "Total People Exit",
            },
            {
              icon: "ri-team-fill",
              title: "Occupancy",
              value: res.data?.zone_count_occ ? res.data.zone_count_occ : 0,
              desc: " Total Occupancy",
            },
            {
              icon: "ri-calendar-event-fill",
              title: "Busiest Day",
              value: res.data?.busiest_day?.count,
              day: res.data?.busiest_day?.date
                ? moment(res.data?.busiest_day?.date).format("DD-MM-YYYY")
                : "--",
              day_letters: res.data?.busiest_day?.date
                ? moment(res.data?.busiest_day?.date).format("dddd")
                : "--",
            },
            {
              icon: "ri-time-fill",
              title: "Busiest Hour",
              value: res.data?.busiest_hour.count,
              hour: res.data?.busiest_hour?.date
                ? res.data?.busiest_hour?.date
                : "----",
            },
          ]);
        })
        .catch((error) => {
          setLoader(false);
          setPeopleCount([
            {
              id: 1,
              icon: "ri-user-shared-fill",
              title: "People In",
              value: 0,
              desc: "Total People In",
            },
            {
              id: 2,
              icon: "ri-user-unfollow-fill",
              title: "People Exit",
              value: 0,
              desc: "Total People Exit",
            },
            {
              id: 3,
              icon: "ri-team-fill",
              title: "Occupancy",
              value: 0,
              desc: " Total Occupancy",
            },
            {
              id: 4,
              icon: "ri-calendar-event-fill",
              title: "Busiest Day",
              desc: "Current Week",
              day: "----",
              day_letters: "",
            },
            {
              id: 5,
              icon: "ri-time-fill",
              title: "Busiest Hour",
              desc: " ",
              hour: "-- --",
              desc: "Current Time",
            },
          ]);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (zoneData) {
      setDashboardState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/dashboard" },
          { title: zoneData.zone_name, link: "#" },
        ],
      });
    }
  }, [zoneData]);

  const addModalToggle = () => {
    setModalShow(!modalShow);
  };

  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  return (
    <React.Fragment>
      {/* {modalShow && (
        <AddCounter
          title={"Add Counter"}
          toggle={addModalToggle}
          isOpen={modalShow}
        />
      )}
      {editModalShow && (
        <EditZone
          title={"Edit Zone"}
          isOpen={editModalShow}
          toggle={editModalToggle}
          item={zoneData}
          setZoneData={setZoneData}
        />
      )} */}

      <div className="page-content">
        {loader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-55% -50%)",
            }}
          >
            <GraphLoader />
          </div>
        ) : (
          <Container fluid>
            <CustomBreadcrumbs
              title={zoneData.zone_name}
              breadcrumbItems={dashboardState.breadcrumbItems}
              zone={zoneData.id}
              setZoneData={setZoneData}
            />
            {/* <div
              className="disappearing-button-container-page-top-right"
              style={{ marginRight: "-60px" }}
            >
              <Button
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#1976D2",
                  borderColor: "#1976D2",
                }}
                type="button"
                className="w-xs waves-effect waves-light me-1 "
                onClick={() => {
                  editModalToggle();
                }}
              >
                Edit Zone
              </Button>
              <Button
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#1976D2",
                  borderColor: "#1976D2",
                }}
                type="button"
                className="w-xs waves-effect waves-light me-1 "
                onClick={addModalToggle}
              >
                Add Counter
              </Button>
            </div> */}
            <Row>
              <div
                style={{
                  width: "100%",
                  minHeight: "100px",
                }}
              >
                <table className="zone_table">
                  <tbody>
                    <tr>
                      <td
                        style={{ width: "20%" }}
                      >{`Total area : ${zoneData?.rental_space} sqm `}</td>
                      <td>
                        {"Operation start time : " +
                          moment(zoneData?.start_time, "HH:mm:ss").format(
                            "h:mmA"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>{`Total capacity : ${zoneData?.total_capacity}  `}</td>
                      <td>
                        {" "}
                        {"Operation end time    : " +
                          moment(zoneData?.end_time, "HH:mm:ss").format(
                            "h:mmA"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>{`Allowed capacity : ${zoneData?.threshold}  `}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
            <Row>
              <Col xl={12}>
                <Row xl={5}>
                  <MiniWidgets count={peopleCount} loader={loader} />
                </Row>
              </Col>
            </Row>
            <Row>
              <LineGraph title={""} dataToSend={id} />
            </Row>
            <Row>
              <Col sm={6}>
                <HeatMapChart title={"Visitor Count Matrix"} />
              </Col>
              <Col sm={6}>
                <BarGraph title={"Visitor Distribution"} />
              </Col>
            </Row>

            <Row>
              <Counter id={id} />
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}
