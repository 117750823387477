import React, { useEffect, useRef, useState } from "react";
import { CardBody, Col, Container, Card, Row } from "reactstrap";
import { APP_NAME } from "../../../../helpers/Constants";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
// import { axios } from "../../../axiosInstance";
// import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Toastr } from "../../../../helpers/Alert";
import LockIcon from "@material-ui/icons/Lock";
import Unlock from "@material-ui/icons/LockOpen";
import Error from "../../../../helpers/Error";
import { useNavigate } from "react-router";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { DetailsTable } from "./DetailsTable";
import { useParams } from "react-router-dom";
import Moments from "../../../../helpers/Moment";
import GraphLoader from "../../../../components/GraphLoader/GraphLoader";
import Visibility from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditSensor from "../EditSensor";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axisCam from "../../../../assets/images/axis.png";
import milesightCam from "../../../../assets/images/milesight.webp";
import nolivestream from "../../../../assets/images/liveNotAvailable.png";
import Swal from "sweetalert2";
import { Loader } from "../../../../components/loader/Loader";
import EditCounter from "../../Zone/Counter/EditCounter";

export const SensorDetails = () => {
  const [modalShow, setModalShow] = useState(false);

  const tableRef = useRef();
  const [imageLoading, setImageLoading] = useState(true);
  // const camera = useSelector((state) => state.cameraSlice.camera);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Sensor Details ", link: "#" },
    ],
  });
  const [showPassword, setShowPassword] = useState(false);
  const [sensor, setSensor] = useState(null);
  const [editData, setEditData] = useState({});

  const [tabValue, setTabValue] = useState(0);
  const [cameraData, setCameraData] = useState([]);
  const [editModalShow, setEditModalShow] = useState(false);
  const [imageError, setImageError] = useState(false);
  const { id } = useParams();
  const [liveCam, setLiveCam] = useState("");
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      const url = `/abaci_count/sensors/${id}`;
      axios
        .get(url)
        .then((response) => {
          setSensor(response.data);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);

          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }, [id]);

  useEffect(() => {
    const url = `/abaci_count/camera_live_view/${id}`;
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.url.split("@")[1]);
        setLiveCam("http://" + response.data.url.split("@")[1]);
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    tableRef.current.onQueryChange({ page: 0, filters: [], search: "" });
    setLoading(true);
  };
  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  let styles = {
    textTransform: "none",
    minWidth: "125px",
    width: "130px",
    fontSize: "14px",
  };

  const UpdateHandler = (method) => {
    if (method === "Disabled") {
      const url = `/abaci_count/sensors/${id}`;

      axios
        .patch(url, { status: method })
        .then((response) => {
          // props.tableRef.current.onQueryChange();
          setSensor(response.data);
          Toastr("success", " Sensor Details Updated Succesfully");
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else if (method === "Active") {
      const url = `/abaci_count/sensors/${id}`;

      axios
        .patch(url, { status: method })
        .then((response) => {
          // props.tableRef.current.onQueryChange();
          setSensor(response.data);

          Toastr("success", "Sensor Details Updated Succesfully");
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else if (method === "Deleted") {
      const url = `/abaci_count/sensors/${id}`;

      axios
        .delete(url)
        .then((response) => {
          // props.tableRef.current.onQueryChange();
          Toastr("success", "Sensor Details Deleted Succesfully");
          setLoader(false);
          navigate("/sensor");
        })
        .catch((error) => {
          setLoader(false);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  };

  const handleImageError = () => {
    setImageError(true);
    setLoading(false);
  };

  const handleImageLoad = () => {
    setImageLoading(false); // Image has loaded successfully
  };
  const imageUrl = imageError || !sensor?.is_online ? nolivestream : liveCam;

  const editCounterModalToggle = (data) => {
    setEditData(data);

    setModalShow(!modalShow);
  };
  return (
    <React.Fragment>
      {editModalShow && (
        <EditSensor
          title={"Edit Sensor"}
          isOpen={editModalShow}
          toggle={editModalToggle}
          item={sensor}
          setSensor={setSensor}
        />
      )}
      {modalShow && (
        <EditCounter
          title={"Edit Counter Line"}
          toggle={editCounterModalToggle}
          isOpen={modalShow}
          item={editData}
          tableRef={tableRef}
        />
      )}

      <div className="page-content">
        {loader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-55% -50%)",
            }}
          >
            <GraphLoader />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title="Sensor Details"
              breadcrumbItems={dashboardState.breadcrumbItems}
            ></Breadcrumbs>

            <Row>
              <Col xl={4}>
                <Card style={{ minHeight: "680px" }}>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <img
                        alt=""
                        src={
                          sensor.manufacturer &&
                          sensor.manufacturer.manufacturer_name === "Axis"
                            ? axisCam
                            : milesightCam
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          marginTop: "-15px",
                        }}
                      />

                      <i
                        style={{
                          marginTop: "-5px",
                          color: sensor?.is_online ? "#19B645" : "red",
                        }}
                        className="ri-checkbox-blank-circle-fill"
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      {imageLoading && (
                        <div
                          style={{
                            width: "300px",
                            height: "200px",
                            backgroundColor: "black",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "21%",
                              left: "49%",
                              transform: "tarnslate(-49%,-21%)",
                            }}
                          >
                            <Loader />
                          </span>
                        </div>
                      )}

                      <img
                        alt=""
                        src={imageUrl}
                        style={{
                          width: "300px",
                          height: "200px",
                          border: "1px solid #CACACA",
                          display: imageLoading ? "none" : "block",
                        }}
                        onError={handleImageError}
                        onLoad={handleImageLoad}
                      />
                    </div>

                    <table
                      cellSpacing="0"
                      cellPadding="4"
                      style={{ width: "100%", marginTop: "10px" }}
                      className="sensor_details_table"
                    >
                      <tbody>
                        <tr className="text-muted">
                          <td style={{ width: "80%" }}>
                            {" "}
                            {`Sensor Name :  ${sensor?.sensor_name}`}
                          </td>
                        </tr>
                        <tr className="text-muted">
                          <td style={{ width: "80%" }}>
                            {" "}
                            {`Manufacturer :  ${
                              sensor?.manufacturer?.manufacturer_name &&
                              sensor.manufacturer.manufacturer_name
                            }`}
                          </td>
                        </tr>
                        <tr className="text-muted">
                          <td>
                            Part Number : {sensor?.part_number.part_number}
                          </td>
                        </tr>
                        <tr className="text-muted">
                          <td>IP Address : {sensor?.ip_address}</td>
                        </tr>
                        <tr className="text-muted">
                          {sensor?.manufacturer?.manufacturer_name ===
                            "Axis" && (
                            <td>Serial Number : {sensor?.serial_number}</td>
                          )}
                        </tr>

                        <tr className="text-muted">
                          <td>MAC Address : {sensor?.mac_add}</td>
                        </tr>
                        <tr className="text-muted">
                          <td>
                            Boundary Sensor :{" "}
                            {sensor?.is_site_boundary_sensor ? "Yes" : "No"}
                          </td>
                        </tr>
                        <tr className="text-muted">
                          {sensor?.manufacturer.manufacturer_name ===
                            "Axis" && <td>Port : {sensor?.port}</td>}
                        </tr>
                        {sensor?.manufacturer.manufacturer_name === "Axis" && (
                          <>
                            {" "}
                            <tr className="text-muted">
                              <td>Username : {sensor?.username}</td>
                            </tr>
                            <tr className="text-muted">
                              {/* <td>Password :  {sensor?.password}</td> */}
                              <td>
                                {/* {showPassword ? sensor?.password : "********"} */}

                                {showPassword
                                  ? `Password : ${sensor?.password}`
                                  : `Password : ********`}
                              </td>
                              <td>
                                {showPassword ? (
                                  <VisibilityOff
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    style={{
                                      fontSize: "15px",
                                      marginTop: "-2px",
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <Visibility
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    style={{
                                      fontSize: "15px",
                                      marginTop: "-2px",
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        )}

                        <tr className="text-muted">
                          <td>
                            Status :{" "}
                            <span
                              style={{
                                color:
                                  sensor?.status === "Active"
                                    ? "#19B645"
                                    : "red",
                              }}
                            >
                              {sensor?.status}
                            </span>
                          </td>
                        </tr>
                        <tr className="text-muted">
                          <td>
                            Created Date : {Moments(sensor?.created_date)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div
                      className="d-flex justify-content-center"
                      style={{
                        width: "100%",
                        position: "absolute",
                        bottom: "5px",
                      }}
                    >
                      <div style={{ marginLeft: "-20px" }}>
                        {" "}
                        <Tooltip arrow title="Edit">
                          <IconButton onClick={editModalToggle}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={
                            sensor?.status === "Active" ? "Disable" : "Activate"
                          }
                        >
                          <IconButton
                            onClick={() =>
                              UpdateHandler(
                                sensor?.status === "Active"
                                  ? "Disabled"
                                  : "Active"
                              )
                            }
                          >
                            {sensor?.status === "Active" ? (
                              <LockIcon />
                            ) : (
                              <Unlock />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Delete">
                          <IconButton
                            onClick={() =>
                              Swal.fire({
                                title: "Are you sure?",
                                icon: "info",
                                text: "You won't be able to revert this!",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler("Deleted");
                                  // deleteClientData(rowData?.id)
                                }
                              })
                            }
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={8}>
                <Card style={{ minHeight: "680px" }}>
                  <CardBody>
                    <div
                      className="d-flex "
                      style={{ width: "100%", paddingLeft: "40px" }}
                    >
                      <Box
                        sx={{ width: "100%", marginBottom: "5px" }}
                        className="d-flex "
                      >
                        <Tabs
                          value={tabValue}
                          onChange={handleChange}
                          textColor="primary"
                          indicatorColor="primary"
                          aria-label="primary tabs example"
                        >
                          <Tab
                            label="People Count"
                            style={styles}
                            // value={"Entry over time"}
                          />
                          <Tab
                            label="Counting Lines"
                            style={styles}
                            // value={"Entry over time"}
                          />

                          <Tab
                            label="Activity Log"
                            style={styles}
                            // value={"Exit over time"}
                          />
                          <Tab
                            label="Connection Log"
                            style={styles}
                            // value={"Occupancy Distribution"}
                          />
                        </Tabs>
                      </Box>
                    </div>

                    <DetailsTable
                      sensorData={sensor}
                      tableRef={tableRef}
                      activeTab={tabValue}
                      loader={loading}
                      setLoading={setLoading}
                      editCounterModalToggle={editCounterModalToggle}
                    />

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};
